// ProfilePage.js
import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Avatar,
  IconButton,
  Snackbar,
  Alert,
  Slider,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Radio,
  Tooltip,
  InputAdornment,
  Grid,
  Button,
  useMediaQuery,
  Menu,
  MenuItem,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ThemeContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { auth, db, uploadProfilePicture } from '../firebase';
import { signOut, updateProfile, updateEmail } from 'firebase/auth';
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import EditIcon from '@mui/icons-material/Edit';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import LogoutIcon from '@mui/icons-material/Logout';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import PaletteIcon from '@mui/icons-material/Palette';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Helmet } from 'react-helmet';

const ProfileContainer = styled(Container)(({ theme }) => ({
  marginTop: '20px', // Reduced from 100px to 20px to move content up
  marginBottom: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  // Removed minHeight to allow content to start closer to the top
  overflow: 'hidden',
  paddingBottom: '60px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px', // Adjusted for smaller screens
    overflow: 'auto',
  },
}));

const AvatarWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: '16px',
  transition: 'transform 0.3s',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(5px) scale(1.05)',
    '& .edit-icon': {
      opacity: 1,
    },
    '& ~ .top-buttons-left, & ~ .top-buttons-right': {
      transform: 'translateY(5px)',
    },
  },
}));

const EditIconWrapper = styled(Box)({
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#fff',
  backgroundColor: 'rgba(0,0,0,0.5)',
  borderRadius: '50%',
  padding: '4px',
  opacity: 0,
  transition: 'opacity 0.3s',
});

const UsernameBox = styled(Box)({
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PortfolioBox = styled(Grid)(({ theme }) => ({
  marginTop: '40px', // Reduced from 60px to 40px
  width: '100%',
  marginBottom: '30px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '20px',
    marginBottom: '80px',
  },
}));

const InfoBox = styled(Box)(({ theme }) => ({
  padding: '20px',
  textAlign: 'center',
  border: '1px solid',
  borderColor: 'divider',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}));

const TopButtonsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  display: 'flex',
  alignItems: 'center',
  transition: 'transform 0.3s',
  [theme.breakpoints.down('sm')]: {
    top: '5px',
  },
}));

const TopButtonsLeft = styled(TopButtonsContainer)({
  left: '20px',
});

const TopButtonsRight = styled(TopButtonsContainer)({
  right: '20px',
});

const ProfilePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    themeColorName,
    setThemeColorName,
    fontSize,
    setFontSize,
    backgroundMode,
    setBackgroundMode,
    colorMap,
  } = useContext(ThemeContext);
  const [currentUser, setCurrentUser] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [lastUsernameChange, setLastUsernameChange] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [lastEmailChange, setLastEmailChange] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [guideAnchorEl, setGuideAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [changesMade, setChangesMade] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [phoneNotifications, setPhoneNotifications] = useState(false);
  const [userNotifications, setUserNotifications] = useState(false);
  const [verificationStep, setVerificationStep] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setLastUsernameChange(userDoc.data().lastUsernameChange);
          setLastEmailChange(userDoc.data().lastEmailChange);
          setEmailNotifications(userDoc.data().emailNotifications || false);
          setPhoneNotifications(userDoc.data().phoneNotifications || false);
          setUserNotifications(userDoc.data().userNotifications || false);
          setNewEmail(userDoc.data().email || '');
          setNewPhone(userDoc.data().phone || '');
        }
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = () => {
    signOut(auth);
    navigate('/login');
  };

  const handleEditClick = () => {
    setNewUsername(currentUser.displayName || '');
    setNewEmail(currentUser.email || '');
    setNewPhone(currentUser.phone || '');
    setOpenEditDialog(true);
  };

  const handleDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedFile(null);
    setChangesMade(false);
    setVerificationStep(null);
    setVerificationCode('');
  };

  const handleUsernameSave = async () => {
    let updated = false;
    if (!newUsername.trim()) {
      setFeedbackMessage('Username cannot be empty.');
      setOpenSnackbar(true);
      return updated;
    }
    const now = Timestamp.now();
    if (
      lastUsernameChange &&
      now.toDate() - lastUsernameChange.toDate() < 120 * 24 * 60 * 60 * 1000
    ) {
      setFeedbackMessage('You can only change your username every 4 months.');
      setOpenSnackbar(true);
      setOpenEditDialog(false);
      return updated;
    }
    if (currentUser.displayName !== newUsername) {
      await updateProfile(currentUser, { displayName: newUsername });
      await updateDoc(doc(db, 'users', currentUser.uid), {
        displayName: newUsername,
        lastUsernameChange: now,
      });
      setLastUsernameChange(now);
      updated = true;
    }
    return updated;
  };

  const handleEmailSave = async () => {
    let updated = false;
    if (newEmail.trim() === '') {
      if (currentUser.email) {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          email: '',
          lastEmailChange: Timestamp.now(),
        });
        setLastEmailChange(Timestamp.now());
        updated = true;
      }
    } else {
      if (currentUser.email !== newEmail) {
        const now = Timestamp.now();
        if (
          lastEmailChange &&
          now.toDate() - lastEmailChange.toDate() < 120 * 24 * 60 * 60 * 1000
        ) {
          setFeedbackMessage('You can change your email once every 4 months.');
          setOpenSnackbar(true);
          setOpenEditDialog(false);
          return updated;
        }
        try {
          await updateEmail(currentUser, newEmail);
          await updateDoc(doc(db, 'users', currentUser.uid), {
            email: newEmail,
            lastEmailChange: now,
          });
          setLastEmailChange(now);
          updated = true;
        } catch {
          setFeedbackMessage('Failed to update email.');
          setOpenSnackbar(true);
        }
      }
    }
    return updated;
  };

  const handleAvatarChange = async () => {
    if (!selectedFile) return false;
    try {
      const photoURL = await uploadProfilePicture(currentUser.uid, selectedFile);
      await updateProfile(currentUser, { photoURL });
      await updateDoc(doc(db, 'users', currentUser.uid), { photoURL });
      setChangesMade(true);
      return true;
    } catch {
      setFeedbackMessage('Failed to upload avatar.');
      setOpenSnackbar(true);
      return false;
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setChangesMade(true);
    }
  };

  const handleThemeMenuOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleThemeMenuClose = () => {
    setAnchorEl(null);
  };

  const handleThemeColorChange = (event) => {
    setThemeColorName(event.target.value);
    setChangesMade(true);
  };

  const handleFontSizeChange = (event, newValue) => {
    setFontSize(newValue);
    setChangesMade(true);
  };

  const handleBackgroundModeChange = (event) => {
    setBackgroundMode(event.target.value);
    setChangesMade(true);
  };

  const handleCopyUsername = () => {
    navigator.clipboard.writeText(currentUser.displayName || '');
    setFeedbackMessage('Copied Username');
    setOpenSnackbar(true);
  };

  const handleGuideClick = (event) => {
    setGuideAnchorEl(guideAnchorEl ? null : event.currentTarget);
  };

  const openGuideMenu = Boolean(guideAnchorEl);

  const handleVerificationSubmit = () => {
    if (verificationCode === '123456') {
      if (verificationStep === 'Email') {
        setEmailNotifications(true);
        setFeedbackMessage('Email notifications turned on.');
      } else if (verificationStep === 'Phone') {
        setPhoneNotifications(true);
        setFeedbackMessage('Phone notifications turned on.');
      }
      setOpenSnackbar(true);
      setVerificationStep(null);
      setVerificationCode('');
    } else {
      setFeedbackMessage('Invalid verification code.');
      setOpenSnackbar(true);
    }
  };

  const handleSave = async () => {
    let updated = false;
    const usernameUpdated = await handleUsernameSave();
    const emailUpdated = await handleEmailSave();
    const avatarUpdated = await handleAvatarChange();
    if (usernameUpdated || emailUpdated || avatarUpdated) {
      updated = true;
      setChangesMade(true);
    }
    if (updated) {
      setFeedbackMessage('Profile updated successfully.');
      setOpenSnackbar(true);
      handleDialogClose();
    } else {
      setOpenEditDialog(false);
    }
  };

  const handleStatisticClick = (statistic) => {
    setFeedbackMessage(`More info about ${statistic}`);
    setOpenSnackbar(true);
  };

  const colorSelections = [
    { type: 'background', value: 'Light', color: '#ffffff' },
    { type: 'background', value: 'Dim', color: '#6e6e6e' },
    { type: 'background', value: 'Dark', color: '#000000' },
    { type: 'theme', value: 'green', color: colorMap['green'] },
    { type: 'theme', value: 'red', color: colorMap['red'] },
    { type: 'theme', value: 'blue', color: colorMap['blue'] },
    { type: 'theme', value: 'orange', color: colorMap['orange'] },
    { type: 'theme', value: 'purple', color: colorMap['purple'] },
  ];

  return (
    <ProfileContainer maxWidth="sm">
      <Helmet>
        <title>Profile / SPT</title>
        <link rel="icon" href="your-favicon-url-32x32" />
      </Helmet>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="info" sx={{ width: '100%' }}>
          {feedbackMessage}
        </Alert>
      </Snackbar>
      {currentUser && (
        <>
          <TopButtonsLeft className="top-buttons-left">
            <IconButton
              onClick={handleThemeMenuOpen}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
                marginRight: '8px',
                cursor: 'pointer',
                width: isMobile ? '36px' : '60px',
                height: isMobile ? '36px' : '60px',
                boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
                borderRadius: '50%',
              }}
            >
              <PaletteIcon sx={{ fontSize: isMobile ? '20px' : '30px' }} />
            </IconButton>
            <IconButton
              onClick={() => navigate('/upgrade')}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
                marginRight: '16px',
                cursor: 'pointer',
                width: isMobile ? '36px' : '60px',
                height: isMobile ? '36px' : '60px',
                boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
                borderRadius: '50%',
              }}
            >
              <AutoAwesomeIcon sx={{ fontSize: isMobile ? '20px' : '30px' }} />
            </IconButton>
          </TopButtonsLeft>

          <TopButtonsRight className="top-buttons-right">
            <IconButton
              onClick={handleGuideClick}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
                marginRight: '8px',
                cursor: 'pointer',
                width: isMobile ? '36px' : '60px',
                height: isMobile ? '36px' : '60px',
                boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
                borderRadius: '50%',
              }}
            >
              <QuestionMarkIcon sx={{ fontSize: isMobile ? '20px' : '30px' }} />
            </IconButton>
            <IconButton
              onClick={handleLogout}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
                cursor: 'pointer',
                width: isMobile ? '36px' : '60px',
                height: isMobile ? '36px' : '60px',
                boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
                borderRadius: '50%',
              }}
            >
              <LogoutIcon sx={{ fontSize: isMobile ? '20px' : '30px' }} />
            </IconButton>
          </TopButtonsRight>

          <AvatarWrapper onClick={handleEditClick}>
            <Avatar
              src={currentUser.photoURL || ''}
              alt="Profile Picture"
              sx={{
                width: isMobile ? 80 : 100,
                height: isMobile ? 80 : 100,
                border: `2px solid ${
                  backgroundMode === 'Dark' ? '#fff' : colorMap[themeColorName] || colorMap['blue']
                }`,
              }}
            />
            <EditIconWrapper className="edit-icon">
              <EditIcon />
            </EditIconWrapper>
          </AvatarWrapper>
          <UsernameBox>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant={isMobile ? 'h6' : 'h5'}>
                <Tooltip title="Click to copy username" arrow>
                  <span
                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                    onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
                    onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
                    onClick={handleCopyUsername}
                  >
                    @{currentUser.displayName || 'NewUser'}
                  </span>
                </Tooltip>
              </Typography>
            </Box>
          </UsernameBox>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleThemeMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            PaperProps={{
              style: {
                width: isMobile ? '180px' : '220px',
              },
            }}
          >
            <Box
              sx={{
                padding: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <Grid container spacing={1}>
                {colorSelections.map((option) => (
                  <Grid item xs={3} key={option.value}>
                    <Radio
                      checked={
                        (option.type === 'background' && backgroundMode === option.value) ||
                        (option.type === 'theme' && themeColorName === option.value)
                      }
                      onChange={
                        option.type === 'background'
                          ? handleBackgroundModeChange
                          : handleThemeColorChange
                      }
                      value={option.value}
                      sx={{
                        color: option.color,
                        '&.Mui-checked': { color: option.color },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Slider
                value={fontSize}
                onChange={handleFontSizeChange}
                aria-labelledby="font-size-slider"
                step={1}
                marks={false}
                min={13}
                max={19}
                valueLabelDisplay="auto"
                sx={{
                  mx: isMobile ? 1 : 2,
                  width: isMobile ? '60%' : '70%',
                  mt: 1,
                }}
              />
            </Box>
          </Menu>
          <Menu
            anchorEl={guideAnchorEl}
            open={openGuideMenu}
            onClose={() => setGuideAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={() => navigate('/guide')}>Guide</MenuItem>
            <MenuItem onClick={() => navigate('/privacy')}>Privacy</MenuItem>
          </Menu>
          <Dialog
            open={openEditDialog}
            onClose={handleDialogClose}
            sx={{
              '& .MuiDialog-paper': {
                overflow: 'hidden',
                maxWidth: '400px',
                width: '100%',
                [theme.breakpoints.down('sm')]: {
                  maxWidth: '90%',
                },
              },
            }}
          >
            <DialogActions
              sx={{
                justifyContent: 'space-between',
                padding: '8px 24px',
              }}
            >
              <IconButton onClick={handleDialogClose} sx={{ marginLeft: '-12px' }}>
                <CloseIcon />
              </IconButton>
              <IconButton onClick={handleSave} sx={{ marginRight: '-12px' }}>
                <CheckIcon />
              </IconButton>
            </DialogActions>
            <DialogContent
              sx={{
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '16px',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  cursor: 'pointer',
                  marginBottom: '16px',
                }}
                onClick={() => document.getElementById('avatar-input').click()}
              >
                <Avatar
                  src={
                    selectedFile
                      ? URL.createObjectURL(selectedFile)
                      : currentUser.photoURL
                  }
                  alt="Avatar Preview"
                  sx={{
                    width: isMobile ? 80 : 100,
                    height: isMobile ? 80 : 100,
                  }}
                />
                <IconButton
                  component="label"
                  sx={{
                    position: 'absolute',
                    top: '45%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#fff',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    borderRadius: '50%',
                    padding: '4px',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <EditIcon />
                  <input
                    id="avatar-input"
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleFileSelect}
                  />
                </IconButton>
              </Box>
              <TextField
                margin="dense"
                placeholder="Email"
                type="email"
                fullWidth
                variant="standard"
                value={newEmail}
                onChange={(e) => {
                  setNewEmail(e.target.value);
                  setChangesMade(true);
                }}
                sx={{ mb: 2, maxWidth: '300px', width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                  style: { textAlign: 'center' },
                  autoComplete: 'off',
                }}
              />
              <TextField
                margin="dense"
                placeholder="Phone"
                type="tel"
                fullWidth
                variant="standard"
                value={newPhone}
                onChange={(e) => {
                  setNewPhone(e.target.value);
                  setChangesMade(true);
                }}
                sx={{ mb: 2, maxWidth: '300px', width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                  style: { textAlign: 'center' },
                  autoComplete: 'off',
                }}
              />
              <TextField
                margin="dense"
                placeholder="Username"
                type="text"
                fullWidth
                variant="standard"
                value={newUsername}
                onChange={(e) => {
                  setNewUsername(e.target.value);
                  setChangesMade(true);
                }}
                sx={{ mb: 2, maxWidth: '300px', width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                  style: { textAlign: 'center' },
                  autoComplete: 'off',
                }}
              />
            </DialogContent>
          </Dialog>
          {verificationStep && (
            <Dialog open={true} onClose={() => setVerificationStep(null)}>
              <DialogContent>
                <Typography variant="h6">
                  Enter verification code sent to your {verificationStep}
                </Typography>
                <TextField
                  margin="dense"
                  placeholder="Verification Code"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  sx={{ mb: 2 }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setVerificationStep(null)}>Cancel</Button>
                <Button onClick={handleVerificationSubmit}>Verify</Button>
              </DialogActions>
            </Dialog>
          )}
          <PortfolioBox
            container
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <InfoBox onClick={() => handleStatisticClick('Portfolio')}>
                <Typography variant="h6">Portfolio</Typography>
                <Typography variant="body1">Data</Typography>
              </InfoBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoBox onClick={() => handleStatisticClick('Notifications')}>
                <NotificationsIcon sx={{ fontSize: 40 }} />
                <Typography variant="h6">Notifications</Typography>
                <Typography variant="body1">Data</Typography>
              </InfoBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoBox onClick={() => handleStatisticClick('Resources')}>
                <Typography variant="h6">Resources</Typography>
                <Typography variant="body1">Data</Typography>
              </InfoBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoBox onClick={() => handleStatisticClick('Aid')}>
                <Typography variant="h6">Aid</Typography>
                <Typography variant="body1">Data</Typography>
              </InfoBox>
            </Grid>
          </PortfolioBox>
        </>
      )}
    </ProfileContainer>
  );
};

export default ProfilePage;