import React, { useState } from 'react';
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  IconButton,
  Tooltip,
  InputAdornment,
  useMediaQuery,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { jsPDF } from 'jspdf';
import { MdSearch, MdClose, MdFileDownload } from 'react-icons/md';
import { Helmet } from 'react-helmet';

const PrivacyTermsPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');

  const sections = [
    // Paste your exact sections here, keeping all text as is.
  ];

  const filteredSections = sections
    .map((section) => ({
      ...section,
      content: section.content
        .split('\n')
        .filter((line) => line.toLowerCase().includes(searchQuery.toLowerCase()))
        .join('\n'),
    }))
    .filter((section) => section.content);

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    let yPosition = 20;

    sections.forEach((section) => {
      doc.setFontSize(16);
      doc.text(section.label, 10, yPosition);
      yPosition += 10;
      doc.setFontSize(12);
      const lines = doc.splitTextToSize(section.content, 180);
      doc.text(lines, 10, yPosition);
      yPosition += lines.length * 7;
      yPosition += 5;
    });

    const string = doc.output('dataurlstring');
    const newWindow = window.open();
    newWindow.document.write(
      `<iframe width='100%' height='100%' src='${string}'></iframe>`
    );
  };

  return (
    <>
      <Helmet>
        <title>Privacy / SPT</title>
        <link rel="icon" href="your-favicon-url" />
      </Helmet>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder=""
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              mb: 0,
              borderRadius: '25px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '25px',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Search" arrow>
                    <IconButton>
                      <MdSearch size={24} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              endAdornment: searchQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchQuery('')}>
                    <MdClose size={24} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <IconButton onClick={handleDownloadPDF} sx={{ ml: 1 }}>
            <MdFileDownload size={24} />
          </IconButton>
        </Box>
        {filteredSections.length === 0 ? (
          <Typography variant="body1">No results found.</Typography>
        ) : (
          filteredSections.map((section, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">{section.label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                  {section.content}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Container>
    </>
  );
};

export default PrivacyTermsPage;