// Notifications.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  Switch,
  FormControlLabel,
  InputAdornment,
  TextField,
  Button,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';

const NotificationsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: '20px',
});

const SettingsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  maxWidth: '300px',
});

const Notifications = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [phoneNotifications, setPhoneNotifications] = useState(false);
  const [userNotifications, setUserNotifications] = useState(false);
  const [verificationStep, setVerificationStep] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(query(collection(db, 'notifications'), orderBy('timestamp', 'desc')), (snapshot) => {
      snapshot.forEach((doc) => {
        // Handle real-time notifications if needed
      });
    });
    return () => unsubscribe();
  }, []);

  const handleEmailNotificationChange = (event) => {
    const value = event.target.checked;
    if (value) {
      setVerificationStep('Email');
    } else {
      setEmailNotifications(false);
      // Update in database if necessary
      setFeedbackMessage('Email notifications turned off.');
      setOpenSnackbar(true);
    }
  };

  const handlePhoneNotificationChange = (event) => {
    const value = event.target.checked;
    if (value) {
      setVerificationStep('Phone');
    } else {
      setPhoneNotifications(false);
      // Update in database if necessary
      setFeedbackMessage('Phone notifications turned off.');
      setOpenSnackbar(true);
    }
  };

  const handleUserNotificationChange = (event) => {
    const value = event.target.checked;
    setUserNotifications(value);
    // Update in database if necessary
    setFeedbackMessage(`User notifications turned ${value ? 'on' : 'off'}.`);
    setOpenSnackbar(true);
  };

  const handleVerificationSubmit = () => {
    if (verificationCode === '123456') {
      if (verificationStep === 'Email') {
        setEmailNotifications(true);
        setFeedbackMessage('Email notifications turned on.');
      } else if (verificationStep === 'Phone') {
        setPhoneNotifications(true);
        setFeedbackMessage('Phone notifications turned on.');
      }
      setOpenSnackbar(true);
      setVerificationStep(null);
      setVerificationCode('');
    } else {
      setFeedbackMessage('Invalid verification code.');
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>Notifications / SPT</title>
        <link rel="icon" href="your-favicon-url-32x32" />
      </Helmet>
      <NotificationsContainer>
        <SettingsBox>
          <FormControlLabel
            control={
              <Switch
                checked={emailNotifications}
                onChange={handleEmailNotificationChange}
                color="primary"
              />
            }
            label="Email Notifications"
          />
          <FormControlLabel
            control={
              <Switch
                checked={phoneNotifications}
                onChange={handlePhoneNotificationChange}
                color="primary"
              />
            }
            label="Phone Notifications"
          />
          <FormControlLabel
            control={
              <Switch
                checked={userNotifications}
                onChange={handleUserNotificationChange}
                color="primary"
              />
            }
            label="User Notifications"
          />
          {verificationStep && (
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Typography variant="body1">Enter verification code for {verificationStep}:</Typography>
              <TextField
                variant="standard"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Verification Code"
                sx={{ mt: 1, width: '100%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={handleVerificationSubmit} variant="contained" size="small">
                        Verify
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
        </SettingsBox>
      </NotificationsContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="info" sx={{ width: '100%' }}>
          {feedbackMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Notifications;