// UpgradePage.js

import React, { useState, useContext } from 'react';
import {
  Box,
  Grid,
  Paper,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import { IoDiamond } from 'react-icons/io5';
import { GiEmerald } from 'react-icons/gi';
import { ThemeContext } from '../App';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useTheme } from '@mui/material/styles';
import { auth } from '../firebase';
import { getIdToken } from 'firebase/auth';
import { Helmet } from 'react-helmet';

const pricingOptions = [
  { value: 'annual', label: 'Annual' },
  { value: 'monthly', label: 'Monthly' },
];

const plans = [
  {
    name: 'Diamond',
    monthly: '$4',
    annual: '$30',
    icon: IoDiamond,
    perks: [
      'All platinum features',
      'Priority event booking',
      'Personalized hub',
      'Customer support',
      'Exclusive webinars',
      'Early access to new features',
    ],
  },
  {
    name: 'Emerald',
    monthly: '$8',
    annual: '$60',
    icon: GiEmerald,
    perks: [
      'All diamond features',
      'Ultimate event access',
      'VIP Event invitations',
      '24/7 Support',
      'Dedicated account manager',
      'Custom integrations',
    ],
  },
];

const UpgradePage = () => {
  const [pricingType, setPricingType] = useState('annual');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { themeColor, backgroundMode } = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePricingToggle = (event, newType) => {
    if (newType) setPricingType(newType);
  };

  const handleSubscribe = async (plan) => {
    if (!plan) return;
    try {
      const user = auth.currentUser;
      if (!user) {
        alert('Log in to upgrade!');
        return;
      }
      const token = await getIdToken(user);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/subscribe/subscribe`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ plan: plan.name }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        stripe.redirectToCheckout({ sessionId: data.sessionId });
      } else {
        alert(data.msg || 'Failed to create subscription. Please try again.');
      }
    } catch {
      alert('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: isMobile ? '20px 10px' : '30px 20px',
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        minHeight: '100vh',
        overflowY: 'auto',
        marginTop: '-20px',
      }}
    >
      <Helmet>
        <title>Upgrade / SPT</title>
        <link rel="icon" href="your-favicon-url-32x32" />
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1px',
          mb: '24px',
          borderRadius: '50px',
          overflow: 'hidden',
          border: `1px solid ${themeColor}`,
          width: isMobile ? '80%' : '200px',
          margin: '0 auto',
        }}
      >
        <ToggleButtonGroup
          value={pricingType}
          exclusive
          onChange={handlePricingToggle}
          aria-label="pricing type"
          sx={{ width: '100%' }}
        >
          {pricingOptions.map((option) => (
            <ToggleButton
              key={option.value}
              value={option.value}
              aria-label={option.label}
              sx={{
                flex: 1,
                backgroundColor:
                  pricingType === option.value
                    ? themeColor
                    : theme.palette.background.paper,
                color: pricingType === option.value ? '#fff' : themeColor,
                border: 'none',
                borderRadius: 0,
                padding: '12px 0',
                '&:hover': {
                  backgroundColor:
                    pricingType === option.value
                      ? themeColor
                      : theme.palette.background.default,
                },
              }}
            >
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{ maxWidth: '800px', width: '100%', padding: '0 20px', margin: '0 auto' }}
      >
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} key={plan.name}>
            <PlanCard
              plan={plan}
              isSelected={selectedPlan === index}
              onSelect={() => setSelectedPlan(index)}
              pricingType={pricingType}
              themeColor={themeColor}
              backgroundMode={backgroundMode}
              onSubscribe={handleSubscribe}
              isMobile={isMobile}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const PlanCard = ({
  plan,
  isSelected,
  onSelect,
  pricingType,
  themeColor,
  backgroundMode,
  onSubscribe,
  isMobile,
}) => {
  const theme = useTheme();
  const IconComponent = plan.icon;
  const textColor =
    backgroundMode === 'Dark' || backgroundMode === 'Dim' ? '#fff' : theme.palette.text.primary;
  return (
    <Paper
      onClick={onSelect}
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: '12px',
        padding: isMobile ? '16px' : '24px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        border: isSelected ? `2px solid ${themeColor}` : '1px solid #ddd',
        transform: isSelected ? 'scale(1.02)' : 'scale(1)',
        transition: 'transform 0.3s, border 0.3s',
        cursor: 'pointer',
        width: '100%',
        boxSizing: 'border-box',
        boxShadow: isSelected
          ? '0 10px 20px rgba(0,0,0,0.2)'
          : '0 2px 4px rgba(0,0,0,0.1)',
        justifyContent: 'space-between',
        height: '100%',
      }}
      elevation={isSelected ? 6 : 3}
    >
      <Box>
        <Tooltip title={plan.name} arrow>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: '8px',
            }}
          >
            <IconComponent size={40} color={themeColor} />
          </Box>
        </Tooltip>
        <Box
          sx={{
            color: themeColor,
            fontWeight: '700',
            mb: '4px',
          }}
        >
          {plan.name}
        </Box>
        <Box
          sx={{
            color: textColor,
            fontWeight: '600',
            mb: '8px',
          }}
        >
          <Box component="span" sx={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
            {pricingType === 'monthly' ? plan.monthly : plan.annual}
          </Box>{' '}
          / {pricingType === 'monthly' ? 'month' : 'year'}
        </Box>
        <Box sx={{ textAlign: 'left', mb: isMobile ? 1 : 2 }}>
          {plan.perks.map((perk, i) => (
            <Box
              key={i}
              sx={{
                fontSize: '14px',
                color: textColor,
                mb: 0.75,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CheckCircleIcon
                sx={{ color: themeColor, mr: 0.5, fontSize: '14px' }}
              />
              {perk}
            </Box>
          ))}
        </Box>
      </Box>
      <Button
        variant="contained"
        sx={{
          backgroundColor: themeColor,
          color: '#fff',
          borderRadius: '30px',
          padding: '10px 20px',
          fontWeight: '600',
          textTransform: 'none',
          '&:hover': { backgroundColor: themeColor, opacity: 0.9 },
          alignSelf: 'center',
          fontSize: '14px',
        }}
        onClick={(e) => {
          e.stopPropagation();
          onSubscribe(plan);
        }}
      >
        Subscribe & Pay
      </Button>
      <Box
        sx={{
          position: 'absolute',
          top: '12px',
          right: '12px',
          backgroundColor: isSelected ? themeColor : theme.palette.background.paper,
          color: isSelected ? '#fff' : themeColor,
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
        }}
      >
        {isSelected ? (
          <CheckCircleIcon fontSize="small" />
        ) : (
          <RadioButtonUncheckedIcon fontSize="small" />
        )}
      </Box>
    </Paper>
  );
};

export default UpgradePage;