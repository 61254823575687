// src/p/Event.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Avatar,
  IconButton,
  TextField,
  Button,
  Rating,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Paper,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import {
  doc,
  getDoc,
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import { auth } from '../firebase';
import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EditIcon from '@mui/icons-material/Edit';

const EventPage = () => {
  const { id } = useParams();
  const [eventData, setEventData] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [starRating, setStarRating] = useState(0);
  const [comments, setComments] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [mainImage, setMainImage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editedEventData, setEditedEventData] = useState({});

  useEffect(() => {
    const fetchEvent = async () => {
      const docRef = doc(db, 'events', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setEventData(docSnap.data());
        setMainImage(docSnap.data().images ? docSnap.data().images[0] : '');
      } else {
        console.log('No such document!');
      }
    };

    fetchEvent();
  }, [id]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user && eventData) {
        setIsCreator(user.uid === eventData.creatorId);
      }
    });
    return () => unsubscribe();
  }, [eventData]);

  useEffect(() => {
    const commentsRef = collection(db, 'events', id, 'comments');
    const q = query(commentsRef, orderBy('timestamp', 'desc'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const commentsList = [];
      querySnapshot.forEach((doc) => {
        commentsList.push({ id: doc.id, ...doc.data() });
      });
      setComments(commentsList);
    });

    return () => unsubscribe();
  }, [id]);

  const handleCommentSubmit = async () => {
    if (!currentUser) {
      alert('Please log in to post a comment.');
      return;
    }

    if (commentText.trim() === '') {
      alert('Comment cannot be empty.');
      return;
    }

    const commentsRef = collection(db, 'events', id, 'comments');

    await addDoc(commentsRef, {
      userId: currentUser.uid,
      userName: currentUser.displayName || 'Anonymous',
      userAvatar: currentUser.photoURL || '',
      text: commentText,
      rating: starRating,
      timestamp: new Date(),
    });

    setCommentText('');
    setStarRating(0);
  };

  const handleAddToCalendar = () => {
    // Implement add to calendar functionality
    alert('Add to calendar functionality not implemented.');
  };

  const handleEditEvent = () => {
    setEditMode(true);
    setEditedEventData({ ...eventData });
  };

  const handleSaveEvent = async () => {
    const docRef = doc(db, 'events', id);
    await updateDoc(docRef, editedEventData);
    setEventData(editedEventData);
    setEditMode(false);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {eventData ? (
        <>
          <Grid container spacing={2}>
            {/* Left Side - Images */}
            <Grid item xs={12} md={6}>
              <Box sx={{ position: 'relative' }}>
                <CardMedia
                  component="img"
                  height="400"
                  image={mainImage}
                  alt={eventData.title}
                />
                {isCreator && (
                  <IconButton
                    onClick={handleEditEvent}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              {/* Image Thumbnails */}
              <Box sx={{ display: 'flex', mt: 2 }}>
                {eventData.images &&
                  eventData.images.map((img, index) => (
                    <Paper
                      key={index}
                      sx={{
                        width: 60,
                        height: 60,
                        overflow: 'hidden',
                        cursor: 'pointer',
                        marginRight: 1,
                        border: mainImage === img ? '2px solid blue' : 'none',
                      }}
                      onClick={() => setMainImage(img)}
                    >
                      <img
                        src={img}
                        alt={`Thumbnail ${index + 1}`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    </Paper>
                  ))}
              </Box>
            </Grid>
            {/* Right Side - Details */}
            <Grid item xs={12} md={6}>
              {editMode ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Title"
                  value={editedEventData.title}
                  onChange={(e) =>
                    setEditedEventData({ ...editedEventData, title: e.target.value })
                  }
                  sx={{ mb: 2 }}
                />
              ) : (
                <Typography variant="h4" gutterBottom>
                  {eventData.title}
                </Typography>
              )}

              {editMode ? (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Description"
                  value={editedEventData.description}
                  onChange={(e) =>
                    setEditedEventData({ ...editedEventData, description: e.target.value })
                  }
                  sx={{ mb: 2 }}
                />
              ) : (
                <Typography variant="body1" gutterBottom>
                  {eventData.description}
                </Typography>
              )}

              {/* Additional Details */}
              <Typography variant="body2" gutterBottom>
                <strong>Location(s):</strong>{' '}
                {editMode ? (
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Locations"
                    value={editedEventData.locations.join(', ')}
                    onChange={(e) =>
                      setEditedEventData({
                        ...editedEventData,
                        locations: e.target.value.split(','),
                      })
                    }
                    sx={{ mb: 2 }}
                  />
                ) : (
                  eventData.locations.join(', ')
                )}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Date:</strong>{' '}
                {editMode ? (
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="date"
                    value={editedEventData.date}
                    onChange={(e) =>
                      setEditedEventData({ ...editedEventData, date: e.target.value })
                    }
                    sx={{ mb: 2 }}
                  />
                ) : (
                  eventData.date
                )}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Time:</strong>{' '}
                {editMode ? (
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="time"
                    value={editedEventData.time}
                    onChange={(e) =>
                      setEditedEventData({ ...editedEventData, time: e.target.value })
                    }
                    sx={{ mb: 2 }}
                  />
                ) : (
                  eventData.time
                )}
              </Typography>

              {/* Action Buttons */}
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  startIcon={<CalendarTodayIcon />}
                  onClick={handleAddToCalendar}
                  sx={{ mr: 2 }}
                >
                  Add to Calendar
                </Button>
                <Button variant="contained" startIcon={<AddIcon />}>
                  Check
                </Button>
              </Box>

              {editMode && (
                <Box sx={{ mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleSaveEvent}>
                    Save Changes
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>

          {/* Comments Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Comments
            </Typography>

            {currentUser && (
              <Box sx={{ mb: 4 }}>
                <TextField
                  label="Write a comment..."
                  multiline
                  rows={4}
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <Rating
                    name="star-rating"
                    value={starRating}
                    onChange={(event, newValue) => {
                      setStarRating(newValue);
                    }}
                  />
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleCommentSubmit}
                    sx={{ ml: 2 }}
                  >
                    Post Comment
                  </Button>
                </Box>
              </Box>
            )}

            {/* Display Comments */}
            <List>
              {comments.map((comment) => (
                <React.Fragment key={comment.id}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar src={comment.userAvatar} alt={comment.userName} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          {comment.userName}
                          <Rating
                            value={comment.rating}
                            readOnly
                            size="small"
                            sx={{ ml: 1 }}
                          />
                        </>
                      }
                      secondary={comment.text}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
            </List>
          </Box>
        </>
      ) : (
        <Typography variant="h6">Loading...</Typography>
      )}
    </Container>
  );
};

export default EventPage;