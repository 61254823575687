// Explore.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Container,
  Box,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { MdViewList, MdGridView, MdMic, MdAdd, MdSearch } from 'react-icons/md';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { db, auth } from '../firebase';
import CardComponent from '../c/Card';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { onAuthStateChanged } from 'firebase/auth';
import { ThemeContext } from '../App';

const Explore = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [pinnedEvents, setPinnedEvents] = useState([]);
  const [unPinnedEvents, setUnPinnedEvents] = useState([]);
  const [visibleCount, setVisibleCount] = useState(12);
  const [searchQuery, setSearchQuery] = useState('');
  const [isListView, setIsListView] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const { themeColor } = useContext(ThemeContext);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsSnapshot = await getDocs(
          query(collection(db, 'events'), orderBy('createdAt', 'desc'))
        );
        const allEvents = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        let pinnedEventIds = [];
        if (currentUser) {
          const pinsQuery = query(
            collection(db, 'pins'),
            where('userId', '==', currentUser.uid)
          );
          const pinsSnapshot = await getDocs(pinsQuery);
          pinnedEventIds = pinsSnapshot.docs.map((doc) => doc.data().eventId);
        }

        const pinned = [];
        const unpinned = [];

        allEvents.forEach((event) => {
          if (pinnedEventIds.includes(event.id)) {
            pinned.push(event);
          } else {
            unpinned.push(event);
          }
        });

        setPinnedEvents(pinned);
        setUnPinnedEvents(unpinned);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [currentUser]);

  const filterEvents = (eventsList) =>
    eventsList.filter((event) => {
      const title = event.title || '';
      const locations = event.locations || [];
      const queryLower = searchQuery.toLowerCase();
      return (
        title.toLowerCase().includes(queryLower) ||
        locations.some((loc) => loc.toLowerCase().includes(queryLower))
      );
    });

  const filteredPinnedEvents = filterEvents(pinnedEvents);
  const filteredUnPinnedEvents = filterEvents(unPinnedEvents);

  const handleLoadMore = () => {
    setVisibleCount((prev) => (prev + 12 > 100 ? 100 : prev + 12));
  };

  const handleViewChange = (event, nextView) => {
    if (nextView !== null) {
      setIsListView(nextView === 'list');
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  const handleVoiceSearch = () => {
    if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
      alert('Speech Recognition not supported in this browser.');
      return;
    }

    if (recognition) {
      recognition.stop();
      setRecognition(null);
    } else {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const newRecognition = new SpeechRecognition();
      newRecognition.continuous = false;
      newRecognition.interimResults = false;
      newRecognition.lang = 'en-US';

      newRecognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setSearchQuery(transcript);
        setRecognition(null);
      };

      newRecognition.onerror = () => {
        setRecognition(null);
      };

      newRecognition.start();
      setRecognition(newRecognition);
    }
  };

  return (
    <Container
      sx={{
        padding: '20px',
        paddingTop: '10px',
        minHeight: '100vh',
        backgroundColor: 'background.default',
        overflowY: 'auto',
      }}
    >
      <Helmet>
        <title>Explore / SPT</title>
        <link rel="icon" href="your-favicon-url-32x32" />
      </Helmet>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ marginBottom: '20px', gap: 1, marginTop: '-10px' }}
      >
        <ToggleButtonGroup
          value={isListView ? 'list' : 'grid'}
          exclusive
          onChange={handleViewChange}
          aria-label="view toggle"
          sx={{
            height: '40px',
            '& .MuiToggleButtonGroup-grouped': {
              borderRadius: '4px',
            },
          }}
        >
          <Tooltip title="Grid" arrow>
            <ToggleButton value="grid" aria-label="grid view">
              <MdGridView size={20} />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="List" arrow>
            <ToggleButton value="list" aria-label="list view">
              <MdViewList size={20} />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <TextField
          id="search-input"
          value={searchQuery}
          onChange={handleSearchChange}
          variant="outlined"
          size="small"
          sx={{
            width: '50%',
            borderRadius: '25px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
          }}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="Search" arrow>
                  <IconButton
                    onClick={() => document.getElementById('search-input')?.focus()}
                  >
                    <MdSearch size={20} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchQuery && (
                  <IconButton onClick={clearSearch}>
                    <CloseIcon />
                  </IconButton>
                )}
                <Tooltip title="Mic" arrow>
                  <IconButton onClick={handleVoiceSearch}>
                    <MdMic size={20} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <Tooltip title="Create" arrow>
          <IconButton
            onClick={() => navigate('/create')}
            sx={{
              marginLeft: '8px',
              height: '40px',
              width: '40px',
              fontSize: '24px',
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            <MdAdd size={24} />
          </IconButton>
        </Tooltip>
      </Box>

      {filteredPinnedEvents.length > 0 && (
        <>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Pinned Events
          </Typography>
          <Grid container spacing={2} sx={{ marginLeft: '10px', mb: 4 }}>
            {filteredPinnedEvents.slice(0, visibleCount).map((event) => (
              <Grid
                item
                xs={12}
                sm={isListView ? 12 : 2.4}
                md={isListView ? 12 : 2.4}
                lg={isListView ? 12 : 2.4}
                xl={isListView ? 12 : 2.4}
                key={event.id}
              >
                <CardComponent
                  event={event}
                  isBookmarkPage={false}
                  showIcons={true}
                  layout={isListView ? 'list' : 'grid'}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <Typography variant="h5" sx={{ mb: 2 }}>
        All Events
      </Typography>
      <Grid container spacing={2} sx={{ marginLeft: '10px' }}>
        {filteredUnPinnedEvents.slice(0, visibleCount).map((event) => (
          <Grid
            item
            xs={12}
            sm={isListView ? 12 : 2.4}
            md={isListView ? 12 : 2.4}
            lg={isListView ? 12 : 2.4}
            xl={isListView ? 12 : 2.4}
            key={event.id}
          >
            <CardComponent
              event={event}
              isBookmarkPage={false}
              showIcons={true}
              layout={isListView ? 'list' : 'grid'}
            />
          </Grid>
        ))}
      </Grid>

      {visibleCount < Math.min(filteredUnPinnedEvents.length, 100) && (
        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
          <Tooltip title="Load More" arrow>
            <IconButton
              onClick={handleLoadMore}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                '&:hover': { backgroundColor: theme.palette.primary.light },
              }}
            >
              <MdAdd size={30} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <style jsx global>{`
        ::-webkit-scrollbar {
          width: 12px;
        }
        ::-webkit-scrollbar-track {
          background: ${theme.palette.background.default};
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${theme.palette.primary.main};
          border-radius: 20px;
          border: 3px solid transparent;
          background-clip: content-box;
        }
      `}</style>
    </Container>
  );
};

export default Explore;