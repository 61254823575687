// src/p/Home.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  TextField,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
  Avatar,
  Paper,
} from '@mui/material';
import { Send, AttachFile, Mic } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { auth } from '../firebase';

const Home = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const inputRef = useRef(null);

  const [input, setInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attachedImages, setAttachedImages] = useState([]);
  const [recognition, setRecognition] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const maxImages = 10;

  useEffect(() => {
    setChatHistory([
      {
        role: 'bot',
        content: 'Welcome! How can I assist you today?',
      },
    ]);

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    setLoading(true);
    try {
      const userMessage = { role: 'user', content: input };
      let botResponse = '';

      // Simulate bot response or integrate actual bot logic here
      botResponse = `You said: ${input}`;

      const botMessage = { role: 'bot', content: botResponse };
      setChatHistory([...chatHistory, userMessage, botMessage]);
      setInput('');
      setLoading(false);
      setAttachedImages([]);
    } catch (error) {
      console.error('Error processing response:', error);
      setLoading(false);
    }
  };

  const handleVoiceInput = () => {
    if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
      alert('Speech Recognition not supported in this browser.');
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const newRecognition = new SpeechRecognition();
    newRecognition.continuous = false;
    newRecognition.interimResults = false;
    newRecognition.lang = 'en-US';

    newRecognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setInput(transcript);
      setRecognition(null);
    };

    newRecognition.onerror = () => {
      setRecognition(null);
    };

    newRecognition.start();
    setRecognition(newRecognition);
  };

  return (
    <>
      <Helmet>
        <title>Home / SPT</title>
        <link rel="icon" href="your-favicon-url-32x32" />
      </Helmet>
      <Box
        sx={{
          width: '100%',
          padding: 2,
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: isMobile ? '-33px' : '0px',
        }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            p: 2,
            mt: 2,
            width: '100%',
            maxWidth: '800px',
            height: '400px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
        >
          <List sx={{ width: '100%' }}>
            {chatHistory.map((msg, index) => (
              <ListItem key={index} alignItems="flex-start" sx={{ mb: 2 }}>
                <ListItemText
                  primary={
                    msg.role === 'user'
                      ? currentUser?.displayName || 'You'
                      : 'Spartan Assistant'
                  }
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.primary">
                        {msg.content}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
            {loading && (
              <ListItem>
                <ListItemText
                  primary="Spartan Assistant"
                  secondary={
                    <Typography component="span" variant="body2" color="text.primary">
                      <CircularProgress size={20} />
                    </Typography>
                  }
                />
              </ListItem>
            )}
          </List>
        </Paper>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 1,
            borderTop: `1px solid ${theme.palette.divider}`,
            width: '100%',
            maxWidth: '800px',
            position: 'fixed',
            bottom: 0,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <IconButton component="label" aria-label="Attach files">
            <AttachFile />
            <input type="file" accept="image/*" hidden multiple />
          </IconButton>
          <IconButton aria-label="Record voice" onClick={handleVoiceInput}>
            <Mic />
          </IconButton>
          <TextField
            placeholder="Type your message..."
            variant="outlined"
            fullWidth
            multiline
            rows={1}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '25px',
              },
              mx: 1,
            }}
            inputRef={inputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit" color="primary" disabled={loading}>
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Home;