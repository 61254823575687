// Guide.js
import React, { useState } from 'react';
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  IconButton,
  Tooltip,
  InputAdornment,
  useMediaQuery,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { jsPDF } from 'jspdf';
import { MdSearch, MdClose, MdFileDownload } from 'react-icons/md';
import { Helmet } from 'react-helmet';

const Guide = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [expanded, setExpanded] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sections = [
    {
      title: 'Documentation',
      faqs: [
        { question: 'How to get started?', answer: 'To get started, you need to...' },
        // Add 4 more FAQs
      ],
    },
    {
      title: 'FAQ',
      faqs: [
        { question: 'What is this platform?', answer: 'This platform is designed to...' },
        // Add 4 more FAQs
      ],
    },
    {
      title: 'Resources',
      faqs: [
        { question: 'Where can I find tutorials?', answer: 'Tutorials are available at...' },
        // Add 4 more FAQs
      ],
    },
    {
      title: 'Training',
      faqs: [
        { question: 'How to access training materials?', answer: 'Training materials can be found...' },
        // Add 4 more FAQs
      ],
    },
  ];

  const filteredSections = sections
    .map((section) => ({
      ...section,
      faqs: section.faqs.filter(
        (faq) =>
          faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
          faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    }))
    .filter((section) => section.faqs.length > 0);

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text('Guide', 10, 10);
    sections.forEach((section, sIndex) => {
      doc.text(section.title, 10, 20 + sIndex * 10);
      section.faqs.forEach((faq, fIndex) => {
        doc.text(faq.question, 10, 30 + sIndex * 10 + fIndex * 10);
        doc.text(faq.answer, 20, 35 + sIndex * 10 + fIndex * 10);
      });
    });
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank');
  };

  return (
    <>
      <Helmet>
        <title>Guide / SPT</title>
        <link rel="icon" href="your-favicon-url" />
      </Helmet>
      <Container maxWidth="md" sx={{ mt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            mt: isMobile ? '0px' : '10px',
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder=""
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              mb: 0,
              borderRadius: '25px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '25px',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Search" arrow>
                    <IconButton>
                      <MdSearch size={24} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              endAdornment: searchQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchQuery('')}>
                    <MdClose size={24} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <IconButton onClick={handleDownloadPDF} sx={{ ml: 1 }}>
            <MdFileDownload size={24} />
          </IconButton>
        </Box>
        {filteredSections.length === 0 ? (
          <Typography variant="body1">No results found.</Typography>
        ) : (
          filteredSections.map((section, sIndex) => (
            <Box key={section.title} sx={{ mb: 4 }}>
              <Typography variant="h5" gutterBottom>
                {section.title}
              </Typography>
              {section.faqs.map((faq, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${sIndex}-${index}`}
                  onChange={handleChange(`panel${sIndex}-${index}`)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1">{faq.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ))
        )}
      </Container>
    </>
  );
};

export default Guide;