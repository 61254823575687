// PassbackPage.js
import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { confirmPasswordReset } from 'firebase/auth';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import CheckIcon from '@mui/icons-material/Check';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PassbackPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [passwords, setPasswords] = useState({ newPassword: '', confirmPassword: '' });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    if (passwords.newPassword !== passwords.confirmPassword) {
      setSnackbar({
        open: true,
        message: 'Passwords do not match.',
        severity: 'error',
      });
      setIsSaving(false);
      return;
    }

    try {
      const queryParams = new URLSearchParams(
        window.location.search || window.location.hash.substring(1)
      );
      const oobCode = queryParams.get('oobCode');

      if (!oobCode) {
        setSnackbar({
          open: true,
          message: 'Invalid or missing reset code.',
          severity: 'error',
        });
        setIsSaving(false);
        return;
      }

      await confirmPasswordReset(auth, oobCode, passwords.newPassword);
      setSnackbar({
        open: true,
        message: 'Password has been reset successfully!',
        severity: 'success',
      });
      setIsSuccess(true);
      setTimeout(() => {
        navigate('/login');
      }, 2000); // Redirect after 2 seconds
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error resetting password.',
        severity: 'error',
      });
      setIsSaving(false);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 12,
        mb: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.text.primary,
      }}
    >
      <Helmet>
        <title>Password Reset / SPT</title>
        <link rel="icon" href="your-favicon-url-32x32" />
      </Helmet>
      <Typography variant="h4" gutterBottom textAlign="center">
        Password Reset
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          mt: 2,
          width: '100%',
          maxWidth: '400px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <TextField
          label="New Password"
          name="newPassword"
          type={isPasswordVisible ? 'text' : 'password'}
          value={passwords.newPassword}
          onChange={handleChange}
          fullWidth
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment:
              passwords.newPassword.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
        />
        <TextField
          label="Confirm New Password"
          name="confirmPassword"
          type={isPasswordVisible ? 'text' : 'password'}
          value={passwords.confirmPassword}
          onChange={handleChange}
          fullWidth
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment:
              passwords.confirmPassword.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
        />
        <Button
          variant="contained"
          type="submit"
          fullWidth
          disabled={isSaving || !passwords.newPassword || !passwords.confirmPassword}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            textTransform: 'none',
            fontSize: '1.1rem',
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          {isSuccess ? <CheckIcon /> : 'Save'}
        </Button>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PassbackPage;