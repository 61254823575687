// src/p/Sparxium.js

import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  TextField,
  InputAdornment,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  ListItemIcon,
  Avatar,
  Paper,
  Button,
  ListSubheader,
  Divider,
  Badge,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Search as SearchIcon,
  Mic,
  PushPin,
  Star,
  Close,
} from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTheme } from '@mui/material/styles';
import { auth, db } from '../firebase';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  Timestamp,
  where,
  getDocs,
} from 'firebase/firestore';

const Sparxium = () => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [calendarDate, setCalendarDate] = useState(new Date(2024, 10, 15)); // November 15, 2024
  const [selectedDay, setSelectedDay] = useState(null);
  const [classes, setClasses] = useState(['CS101', 'MATH201', 'PHYS301', 'HIST101']);
  const [companiesData, setCompaniesData] = useState([]);
  const [jobPostingsData, setJobPostingsData] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [forumPosts, setForumPosts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [voiceRecognition, setVoiceRecognition] = useState(null);

  useEffect(() => {
    // Set up authentication listener
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    // Fetch companies data
    const companies = [
      { name: 'Delta Dental of Michigan', category: 'Athletics sponsors' },
      { name: 'McLaren Greater Lansing', category: 'Athletics sponsors' },
      { name: 'State Farm', category: 'Athletics sponsors' },
      { name: 'PepsiCo', category: 'Athletics sponsors' },
      { name: 'AT&T', category: 'Athletics sponsors' },
      { name: 'Health Alliance Plan of Michigan', category: 'Athletics sponsors' },
      { name: 'Michigan Student Aid', category: 'Athletics sponsors' },
      { name: 'PNC Bank', category: 'Athletics sponsors' },
      { name: 'Malleable Minds', category: 'Project sponsors' },
      { name: 'MaxCogito', category: 'Project sponsors' },
      { name: 'Meijer', category: 'Project sponsors' },
      { name: 'Microsoft', category: 'Project sponsors' },
      { name: 'Mozilla', category: 'Project sponsors' },
      { name: 'MSU Federal Credit Union', category: 'Project sponsors' },
      { name: 'Rocket Companies', category: 'Project sponsors' },
      { name: 'Jackson', category: 'Career fair sponsors' },
      { name: 'Magnify II Inc.', category: 'Career fair sponsors' },
      { name: 'MSU College of Law', category: 'Career fair sponsors' },
      { name: 'Community Mental Health', category: 'Career fair sponsors' },
      { name: 'Sazerac Company', category: 'Career fair sponsors' },
      { name: 'Auto-Owners Insurance Company', category: 'Career fair sponsors' },
      { name: 'Engineering Inclusion and Diversity (EGRID)', category: 'Partners and sponsors' },
      { name: 'University Advising Initiative', category: 'Partners and sponsors' },
      { name: 'United Farm Workers of America', category: 'Partners and sponsors' },
      { name: 'The UFW Foundation', category: 'Partners and sponsors' },
    ];
    setCompaniesData(companies);

    // Fetch job postings data
    const jobs = [
      { id: 1, position: 'Software Engineer', location: 'East Lansing, MI', pay: '$80,000' },
      { id: 2, position: 'Data Analyst', location: 'Ann Arbor, MI', pay: '$70,000' },
      { id: 3, position: 'Project Manager', location: 'Detroit, MI', pay: '$90,000' },
      { id: 4, position: 'UX Designer', location: 'Grand Rapids, MI', pay: '$75,000' },
      { id: 5, position: 'System Administrator', location: 'Lansing, MI', pay: '$65,000' },
      { id: 6, position: 'Cybersecurity Analyst', location: 'Flint, MI', pay: '$85,000' },
      { id: 7, position: 'AI Researcher', location: 'Ann Arbor, MI', pay: '$95,000' },
      { id: 8, position: 'Network Engineer', location: 'Kalamazoo, MI', pay: '$70,000' },
      { id: 9, position: 'Technical Writer', location: 'East Lansing, MI', pay: '$60,000' },
      { id: 10, position: 'Database Administrator', location: 'Detroit, MI', pay: '$80,000' },
    ];
    setJobPostingsData(jobs);

    // Fetch leaderboard data
    const leaderboard = [
      { id: 1, name: 'Alice', stars: 5 },
      { id: 2, name: 'Bob', stars: 4 },
      { id: 3, name: 'Charlie', stars: 3 },
      { id: 4, name: 'Diana', stars: 2 },
      { id: 5, name: 'Eve', stars: 1 },
    ];
    setLeaderboardData(leaderboard);

    // Clean up
    return () => {
      unsubscribeAuth();
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  const handleVoiceSearch = () => {
    // Implement voice search functionality
  };

  const handleCalendarChange = (date) => {
    setCalendarDate(date);
  };

  const handleDayClick = (value) => {
    setSelectedDay(value);
    alert(`Create event on ${value}`);
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>Sparxium / SPT</title>
      </Helmet>

      {/* Left Sidebar */}
      <Drawer variant="permanent" sx={{ width: 240 }}>
        <Toolbar />
        <List>
          <ListSubheader>Enrolled Classes</ListSubheader>
          {classes.map((cls, index) => (
            <ListItem button key={index}>
              <ListItemText primary={cls} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1 }}>
        {/* AppBar with Tabs */}
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              centered
              sx={{ flexGrow: 1 }}
            >
              <Tab label="Companies" />
              <Tab label="Calendar" />
              <Tab label="Job Postings" />
              <Tab label="Forum" />
              <Tab label="Leaderboards" />
            </Tabs>
            <TextField
              placeholder="Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{ ml: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {searchQuery && (
                      <InputAdornment position="end">
                        <IconButton onClick={clearSearch} aria-label="Clear search">
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    )}
                    <InputAdornment position="end">
                      <IconButton onClick={handleVoiceSearch} aria-label="Voice search">
                        <Mic />
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Toolbar>
        </AppBar>

        {/* Toolbar Spacer */}
        <Toolbar />

        {/* Tab Content */}
        <Container sx={{ pt: 3 }}>
          {tabValue === 0 && (
            // Companies tab content
            <Box>
              <Grid container spacing={2}>
                {companiesData.map((company, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">{company.name}</Typography>
                        <Typography variant="body2">
                          {company.category}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {tabValue === 1 && (
            // Calendar tab content
            <Box>
              <Calendar
                onChange={handleCalendarChange}
                value={calendarDate}
                onClickDay={handleDayClick}
              />
              {selectedDay && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">
                    Create event on {selectedDay.toDateString()}
                  </Typography>
                  {/* Event creation form can be added here */}
                </Box>
              )}
            </Box>
          )}

          {tabValue === 2 && (
            // Job Postings tab content
            <Box>
              <List>
                {jobPostingsData.map((job) => (
                  <Paper sx={{ mb: 2 }} key={job.id}>
                    <ListItem
                      button
                      onClick={() => alert(`Open job details for ${job.position}`)}
                    >
                      <ListItemText
                        primary={job.position}
                        secondary={`${job.location} - ${job.pay}`}
                      />
                    </ListItem>
                  </Paper>
                ))}
              </List>
            </Box>
          )}

          {tabValue === 3 && (
            // Forum tab content
            <Box sx={{ display: 'flex' }}>
              {/* Left side messages with other users */}
              <Box
                sx={{
                  width: 240,
                  borderRight: 1,
                  borderColor: 'divider',
                  overflowY: 'auto',
                  maxHeight: '80vh',
                }}
              >
                <List>
                  <ListSubheader>Messages</ListSubheader>
                  {/* Sample users */}
                  {['Alice', 'Bob', 'Charlie', 'Diana', 'Eve'].map(
                    (user, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleUserSelect({ id: index, displayName: user })}
                        selected={selectedUser?.id === index}
                      >
                        <ListItemText primary={user} />
                      </ListItem>
                    )
                  )}
                </List>
                <TextField
                  placeholder="Search Users"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ mt: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    // Implement user search functionality
                  }}
                />
              </Box>
              {/* Main forum content */}
              <Box sx={{ flexGrow: 1, p: 2 }}>
                {/* Create new post */}
                <Button
                  variant="contained"
                  sx={{ mb: 2 }}
                  onClick={() => alert('Create new post')}
                >
                  Create New Post
                </Button>
                {/* Existing forum posts */}
                {[1, 2, 3].map((post) => (
                  <Paper sx={{ p: 2, mb: 2 }} key={post}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Post Title {post}
                      </Typography>
                      <IconButton>
                        <PushPin />
                      </IconButton>
                    </Box>
                    <Typography variant="body1">
                      This is the content of post {post}.
                    </Typography>
                  </Paper>
                ))}
              </Box>
            </Box>
          )}

          {tabValue === 4 && (
            // Leaderboards tab content
            <Box>
              <List>
                {leaderboardData.map((user) => (
                  <ListItem
                    button
                    key={user.id}
                    onClick={() => alert(`View profile of ${user.name}`)}
                  >
                    <ListItemIcon>
                      <Avatar>{user.name.charAt(0)}</Avatar>
                    </ListItemIcon>
                    <ListItemText primary={user.name} />
                    <Box>
                      {Array.from({ length: user.stars }, (_, i) => (
                        <Star key={i} color="primary" />
                      ))}
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Sparxium;