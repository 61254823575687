// Login.js
import React, { useState, useContext } from 'react';
import {
  Container,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../App';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Visibility, VisibilityOff, HelpOutline } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

const Login = () => {
  const theme = useTheme();
  const { themeColor } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const isLoginDisabled = !credentials.email.trim() || !credentials.password.trim();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (isLoginDisabled) {
      setSnackbar({
        open: true,
        message: 'Please fill in all fields.',
        severity: 'warning',
      });
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
      setSnackbar({
        open: true,
        message: 'Logged in successfully!',
        severity: 'success',
      });
      navigate('/profile');
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Invalid email or password.',
        severity: 'error',
      });
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 4,
        mb: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        color: theme.palette.text.primary,
      }}
    >
      <Helmet>
        <title>Login / SPT</title>
        <link rel="icon" href="path/to/your/favicon-32x32.png" sizes="32x32" />
      </Helmet>
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          mt: 2,
          width: '100%',
          maxWidth: '400px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <TextField
          label="Email"
          name="email"
          type="email"
          value={credentials.email}
          onChange={handleChange}
          fullWidth
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          aria-label="Email"
        />
        <TextField
          label="Password"
          name="password"
          type={isPasswordVisible ? 'text' : 'password'}
          value={credentials.password}
          onChange={handleChange}
          fullWidth
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          aria-label="Password"
          InputProps={{
            endAdornment:
              credentials.password.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  <IconButton
                    aria-label="Forgot password"
                    onClick={() => navigate('/passback')}
                    edge="end"
                  >
                    <HelpOutline />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !isLoginDisabled) {
              handleLogin(e);
            }
          }}
        />
        <Button
          variant="contained"
          type="submit"
          fullWidth
          disabled={isLoginDisabled}
          sx={{
            backgroundColor: themeColor,
            color: '#fff',
            textTransform: 'none',
            fontSize: '1.1rem',
            '&:hover': { backgroundColor: themeColor },
          }}
          aria-label="Continue"
        >
          Continue
        </Button>
        <Button
          variant="text"
          fullWidth
          onClick={() => navigate('/signup')}
          sx={{
            color: themeColor,
            textTransform: 'none',
            fontSize: '1.1rem',
          }}
          aria-label="Create Account"
        >
          Create Account
        </Button>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;