// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const uploadProfilePicture = async (userId, file) => {
  try {
    const extension = file.name.split('.').pop();
    const storageRefPath = `profilePictures/${userId}/Avatar/avatar.${extension}`;
    const storageRefObj = ref(storage, storageRefPath);
    const snapshot = await uploadBytes(storageRefObj, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    throw error;
  }
};

export const initializeUserDocument = async (userId, displayName) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {
      await setDoc(userDocRef, {
        role: 'User',
        starsCount: 0,
        starsLastReset: new Date(),
        heartsCount: 0,
        heartsLastReset: new Date(),
        commentsCount: 0,
        commentsLastReset: new Date(),
        photoURL: '',
        phoneNumber: '',
        displayName: displayName || 'User',
      });
    }
  } catch (error) {
    console.error('Error initializing user:', error);
  }
};

export default app;