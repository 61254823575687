// src/p/Edu.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  Alert,
} from '@mui/material';
import { Search as SearchIcon, Mic, Close } from '@mui/icons-material';
import { useNavigate, Routes, Route, Navigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material/styles';

// Sample Data for Classes with Exact Credits
const classesData = [
  // Bioscience Courses
  {
    id: 'BS161',
    name: 'Cell and Molecular Biology',
    discipline: 'Bioscience',
    credits: 3,
  },
  {
    id: 'ENT205',
    name: 'Pests, Society and Environment',
    discipline: 'Bioscience',
    credits: 3,
  },
  {
    id: 'IBIO150',
    name: 'Integrating Biology: From DNA to Populations',
    discipline: 'Bioscience',
    credits: 3,
  },
  {
    id: 'MMG141',
    name: 'Introductory Human Genetics',
    discipline: 'Bioscience',
    credits: 3,
  },
  {
    id: 'MMG201',
    name: 'Fundamentals of Microbiology',
    discipline: 'Bioscience',
    credits: 3,
  },
  {
    id: 'PLB105',
    name: 'Plant Biology',
    discipline: 'Bioscience',
    credits: 3,
  },
  {
    id: 'PSL250',
    name: 'Introductory Physiology',
    discipline: 'Bioscience',
    credits: 4,
  },
  // Bioscience Lab Courses
  {
    id: 'BS171',
    name: 'Cell and Molecular Biology Laboratory',
    discipline: 'Bioscience Lab',
    credits: 2,
  },
  {
    id: 'CEM161',
    name: 'Chemistry Laboratory I',
    discipline: 'Bioscience Lab',
    credits: 1,
  },
  {
    id: 'CEM162',
    name: 'Chemistry Laboratory II',
    discipline: 'Bioscience Lab',
    credits: 1,
  },
  {
    id: 'PHY191',
    name: 'Physics Laboratory for Scientists, I',
    discipline: 'Bioscience Lab',
    credits: 1,
  },
  {
    id: 'PHY192',
    name: 'Physics Laboratory for Scientists, II',
    discipline: 'Bioscience Lab',
    credits: 1,
  },
  {
    id: 'PLB106',
    name: 'Plant Biology Laboratory',
    discipline: 'Bioscience Lab',
    credits: 1,
  },
  // Computational Mathematics, Science, & Engineering Courses
  {
    id: 'CMSE201',
    name: 'Computational Modeling and Data Analysis I',
    discipline: 'Computational Mathematics, Science, & Engineering',
    credits: 4,
  },
  {
    id: 'CMSE381',
    name: 'Fundamentals of Data Science Methods',
    discipline: 'Computational Mathematics, Science, & Engineering',
    credits: 4,
  },
  {
    id: 'CMSE382',
    name: 'Optimization Methods in Data Science',
    discipline: 'Computational Mathematics, Science, & Engineering',
    credits: 4,
  },
  {
    id: 'CMSE495',
    name: 'Experiential Learning in Data Science (W)',
    discipline: 'Computational Mathematics, Science, & Engineering',
    credits: 4,
  },
  {
    id: 'CMSE401',
    name: 'Methods for Parallel Computing',
    discipline: 'Computational Mathematics, Science, & Engineering',
    credits: 4,
  },
  {
    id: 'CMSE402',
    name: 'Data Visualization Principles and Techniques',
    discipline: 'Computational Mathematics, Science, & Engineering',
    credits: 3,
  },
  // Computer Science & Engineering Courses
  {
    id: 'CSE232',
    name: 'Introduction to Programming II',
    discipline: 'Computer Science & Engineering',
    credits: 4,
  },
  {
    id: 'CSE300',
    name: 'Social, Ethical, and Professional Issues in Computing',
    discipline: 'Computer Science & Engineering',
    credits: 1,
  },
  {
    id: 'CSE331',
    name: 'Algorithms and Data Structures',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  {
    id: 'CSE380',
    name: 'Information Management and the Cloud',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  {
    id: 'CSE404',
    name: 'Introduction to Machine Learning',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  {
    id: 'CSE482',
    name: 'Big Data Analysis',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  {
    id: 'CSE480',
    name: 'Database Systems',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  {
    id: 'CSE402',
    name: 'Biometrics and Pattern Recognition',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  {
    id: 'CSE415',
    name: 'Introduction to Parallel Computing',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  {
    id: 'CSE431',
    name: 'Algorithm Engineering',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  {
    id: 'CSE440',
    name: 'Introduction to Artificial Intelligence',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  {
    id: 'CSE471',
    name: 'Media Processing and Multimedia Computing',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  {
    id: 'CSE472',
    name: 'Computer Graphics',
    discipline: 'Computer Science & Engineering',
    credits: 3,
  },
  // Math Courses
  {
    id: 'MTH314',
    name: 'Matrix Algebra with Computational Applications',
    discipline: 'Math',
    credits: 3,
  },
  {
    id: 'MTH451',
    name: 'Numerical Analysis I',
    discipline: 'Math',
    credits: 3,
  },
  {
    id: 'MTH468',
    name: 'Predictive Analytics',
    discipline: 'Math',
    credits: 3,
  },
  // Statistics Courses
  {
    id: 'STT180',
    name: 'Introduction to Data Science',
    discipline: 'Statistics',
    credits: 4,
  },
  {
    id: 'STT380',
    name: 'Probability and Statistics for Data Science',
    discipline: 'Statistics',
    credits: 4,
  },
  {
    id: 'STT464',
    name: 'Statistics for Biologists',
    discipline: 'Statistics',
    credits: 3,
  },
  {
    id: 'STT465',
    name: 'Bayesian Statistical Methods',
    discipline: 'Statistics',
    credits: 3,
  },
];

const disciplines = [
  'Bioscience',
  'Bioscience Lab',
  'Computational Mathematics, Science, & Engineering',
  'Computer Science & Engineering',
  'Math',
  'Statistics',
];

// Styled Box for Custom Scrollbar
const CustomScrollbar = styled(Box)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.paper,
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
    border: `3px solid ${theme.palette.background.paper}`,
  },
}));

const Edu = () => {
  const navigate = useNavigate();

  // State for Search Functionality
  const [searchInput, setSearchInput] = useState('');
  const [filteredClasses, setFilteredClasses] = useState(classesData);

  // State for Selected Classes to ensure major requirements
  const [selectedClasses, setSelectedClasses] = useState([]);

  useEffect(() => {
    if (searchInput.trim() === '') {
      setFilteredClasses(classesData);
    } else {
      const filtered = classesData.filter((cls) =>
        cls.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        cls.id.toLowerCase().includes(searchInput.toLowerCase())
      );
      setFilteredClasses(filtered);
    }
  }, [searchInput]);

  const handleClassClick = (classId) => {
    navigate(`classes/${classId}`);
  };

  // Voice Input Handler
  const handleVoiceInput = () => {
    if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
      alert('Speech Recognition not supported in this browser.');
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-US';

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearchInput(transcript);
    };

    recognition.onerror = () => {
      recognition.stop();
    };

    recognition.start();
  };

  const handleClearSearch = () => {
    setSearchInput('');
  };

  // Handle Class Selection
  const toggleSelectClass = (classId) => {
    if (selectedClasses.includes(classId)) {
      setSelectedClasses(selectedClasses.filter((id) => id !== classId));
    } else {
      setSelectedClasses([...selectedClasses, classId]);
    }
  };

  // Calculate Total Credits
  const totalCredits = selectedClasses.reduce((total, classId) => {
    const cls = classesData.find((c) => c.id === classId);
    return total + (cls ? cls.credits : 0);
  }, 0);

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>Edu / SPT</title>
        <link rel="icon" href="your-favicon-url-32x32" />
      </Helmet>

      {/* AppBar with Search Bar */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '100%',
          bgcolor: 'background.paper',
          boxShadow: 1,
          zIndex: 1100,
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <TextField
          placeholder="Search"
          variant="outlined"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          sx={{
            maxWidth: '400px',
            width: '100%',
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {searchInput && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch} aria-label="Clear search">
                      <Close />
                    </IconButton>
                  </InputAdornment>
                )}
                <InputAdornment position="end">
                  <IconButton onClick={handleVoiceInput} aria-label="Record voice">
                    <Mic />
                  </IconButton>
                </InputAdornment>
              </>
            ),
          }}
        />
      </Box>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
          pt: '80px', // To avoid overlap with AppBar
          minHeight: '100vh',
          overflowY: 'auto',
        }}
      >
        {/* Selected Classes Alert */}
        {selectedClasses.length > 0 && (
          <Alert
            severity="info"
            sx={{
              borderRadius: '10px',
              mb: 2,
              maxWidth: '800px',
              margin: '0 auto',
            }}
          >
            Selected Classes: {selectedClasses.length} | Total Credits: {totalCredits}
          </Alert>
        )}

        {/* Classes Grid with Custom Scrollbar */}
        <CustomScrollbar
          sx={{
            px: 2,
            pr: 1,
            mb: 3,
          }}
        >
          <Grid container spacing={3}>
            {/* Group classes by Discipline */}
            {disciplines.map((discipline) => {
              const disciplineClasses = filteredClasses.filter(
                (cls) => cls.discipline === discipline
              );
              return disciplineClasses.length > 0 ? (
                <Box key={discipline} sx={{ width: '100%', mb: 4 }}>
                  <Typography variant="h5" gutterBottom>
                    {discipline}
                  </Typography>
                  <Grid container spacing={3}>
                    {disciplineClasses.map((cls) => (
                      <Grid item xs={12} sm={6} md={3} key={cls.id}>
                        <Card
                          onClick={() => handleClassClick(cls.id)}
                          sx={{
                            cursor: 'pointer',
                            transition: 'transform 0.2s',
                            '&:hover': {
                              transform: 'scale(1.05)',
                            },
                            height: '250px', // Increased height for taller cards
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}
                        >
                          <CardContent>
                            <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                              {cls.id} - {cls.name}
                            </Typography>
                            <Typography color="text.secondary" sx={{ mb: 1 }}>
                              {cls.discipline}
                            </Typography>
                            <Typography variant="body2" color="text.primary">
                              Credits: {cls.credits}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelectClass(cls.id);
                              }}
                              variant={
                                selectedClasses.includes(cls.id) ? 'contained' : 'outlined'
                              }
                              color="primary"
                            >
                              {selectedClasses.includes(cls.id) ? 'Selected' : 'Select'}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : null;
            })}
          </Grid>
        </CustomScrollbar>
      </Box>

      {/* Routing for Class Details */}
      <Routes>
        <Route path="/" element={<Navigate to="classes" replace />} />
        <Route path="classes/:classId" element={<ClassDetail />} />
        <Route path="*" element={<Navigate to="classes" replace />} />
      </Routes>
    </Box>
  );
};

// ClassDetail Component: Displays detailed information about a specific class
const ClassDetail = () => {
  const { classId } = useParams();
  const navigate = useNavigate();

  // Find the class based on classId
  const cls = classesData.find((c) => c.id === classId);

  if (!cls) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h5">Class not found.</Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Button variant="outlined" onClick={() => navigate(-1)} sx={{ mb: 2 }}>
        Back to Classes
      </Button>
      <Card sx={{ maxWidth: '800px', margin: '0 auto' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {cls.id} - {cls.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Discipline: {cls.discipline}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Credits: {cls.credits}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This is a detailed description of the {cls.name} course. Here you can add more
            information about the course objectives, syllabus, prerequisites, and other relevant
            details.
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary">
            Enroll
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default Edu;