// src/c/Card.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Card,
  CardMedia,
  IconButton,
  Box,
  Typography,
  Snackbar,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import {
  IoArrowBack,
  IoArrowForward,
  IoShareOutline,
} from 'react-icons/io5';
import {
  BsChat,
  BsFillHeartFill,
  BsHeart,
} from 'react-icons/bs';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import { useNavigate } from 'react-router-dom';
import { styled, keyframes } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { useSwipeable } from 'react-swipeable';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  deleteDoc,
  doc,
  getDoc,
  addDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { ThemeContext } from '../App';

const DotsContainer = styled(Box)({
  position: 'absolute',
  bottom: '8px',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  gap: '6px',
  zIndex: 2,
});

const Dot = styled(Box)(({ theme, active }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.7)',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
}));

const popAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
`;

const AnimatedIconButton = styled(IconButton)(({ animate }) => ({
  animation: animate ? `${popAnimation} 0.3s ease-in-out` : 'none',
}));

const CardComponent = ({
  event,
  showIcons = true,
  isBookmarkPage = false,
  layout = 'grid',
}) => {
  const { images, title, locations, dateTime, id } = event;
  const navigate = useNavigate();
  const theme = useTheme();
  const { themeColor } = useContext(ThemeContext);

  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState('User');
  const [heartedEvents, setHeartedEvents] = useState([]);
  const [heartCounts, setHeartCounts] = useState({});
  const [pinnedEvents, setPinnedEvents] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [animateHeart, setAnimateHeart] = useState(false);
  const [userData, setUserData] = useState(null);

  const isPinned = pinnedEvents.includes(id);
  const isHearted = heartedEvents.includes(id);
  const heartCount = heartCounts[id] || 0;

  const hasMultipleImages = images && images.length > 1;
  const totalImages = images?.length || 0;

  const [imageIndex, setImageIndex] = useState(0);
  const cardRef = useRef(null);
  const imageRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isImageHovered, setIsImageHovered] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserData(data);
            setUserRole(data.role || 'User');
          } else {
            setUserRole('User');
            setUserData(null);
          }
        } catch {
          setUserRole('User');
          setUserData(null);
        }
      } else {
        setCurrentUser(null);
        setUserRole('User');
        setUserData(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchHearts = async () => {
      if (!currentUser) {
        setHeartedEvents([]);
        setHeartCounts({});
        return;
      }

      try {
        const heartsQuery = query(
          collection(db, 'hearts'),
          where('userId', '==', currentUser.uid)
        );
        const heartsSnapshot = await getDocs(heartsQuery);

        const counts = {};
        const userHearted = [];

        heartsSnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          if (data.eventId) {
            counts[data.eventId] = counts[data.eventId]
              ? counts[data.eventId] + 1
              : 1;
            if (data.userId === currentUser.uid) {
              userHearted.push(data.eventId);
            }
          }
        });

        setHeartCounts(counts);
        setHeartedEvents(userHearted);
      } catch {
        console.error('Error fetching hearts');
      }
    };

    fetchHearts();
  }, [currentUser]);

  useEffect(() => {
    const fetchPins = async () => {
      try {
        const pinsQuery = currentUser
          ? query(
              collection(db, 'pins'),
              where('userId', '==', currentUser.uid)
            )
          : query(collection(db, 'pins'), where('userId', '==', 'guest'));

        const pinsSnapshot = await getDocs(pinsQuery);
        const userPinned = [];

        pinsSnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          if (data.eventId) {
            userPinned.push(data.eventId);
          }
        });

        setPinnedEvents(userPinned);
      } catch {
        console.error('Error fetching pins');
      }
    };

    fetchPins();
  }, [currentUser]);

  useEffect(() => {
    if (openSnackbar) {
      const timer = setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [openSnackbar]);

  const handleCardClick = () => {
    if (openCommentDialog) return;
    navigate(`/events/${id}`);
  };

  const stopPropagation = (e) => e.stopPropagation();

  const handleDotClick = (index) => {
    if (index !== imageIndex) setImageIndex(index);
  };

  const handlePrevImage = (e) => {
    stopPropagation(e);
    setImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNextImage = (e) => {
    stopPropagation(e);
    setImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : prevIndex));
  };

  const shouldShowLeftArrow = isImageHovered && imageIndex > 0 && totalImages > 1;
  const shouldShowRightArrow = isImageHovered && imageIndex < totalImages - 1 && totalImages > 1;

  const getLimit = (action) => {
    const role = userRole;
    if (action === 'hearts') {
      return role === 'Emerald' ? 12 : role === 'Diamond' ? 6 : 3;
    } else if (action === 'pins') {
      return currentUser ? 6 : 3;
    } else {
      return 3;
    }
  };

  const isResetNeeded = (lastReset) => {
    if (!lastReset) return true;
    const lastResetDate = lastReset.toDate();
    const now = new Date();
    const diff = now - lastResetDate;
    return diff > 7 * 24 * 60 * 60 * 1000;
  };

  const updateUserData = async (updates) => {
    if (!currentUser) return;
    const userDocRef = doc(db, 'users', currentUser.uid);
    await updateDoc(userDocRef, updates);
    setUserData((prev) => ({ ...prev, ...updates }));
  };

  const addHeart = async (eventId) => {
    if (!currentUser) return false;

    const limit = getLimit('hearts');
    const count = userData?.heartsCount || 0;

    let needReset = isResetNeeded(userData?.heartsLastReset);

    if (needReset) {
      await updateUserData({ heartsCount: 0, heartsLastReset: Timestamp.now() });
    }

    if (count >= limit && !needReset) {
      return false;
    }

    const heartDocId = `${eventId}_${currentUser.uid}`;
    const heartRef = doc(db, 'hearts', heartDocId);
    try {
      const existingHeart = await getDoc(heartRef);
      if (!existingHeart.exists()) {
        await setDoc(heartRef, {
          eventId,
          userId: currentUser.uid,
          timestamp: Timestamp.now(),
        });
        await updateUserData({ heartsCount: (userData?.heartsCount || 0) + 1 });
        setHeartedEvents((prev) => [...prev, eventId]);
        setHeartCounts((prev) => ({
          ...prev,
          [eventId]: (prev[eventId] || 0) + 1,
        }));
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  const removeHeart = async (eventId) => {
    if (!currentUser) return;

    const heartDocId = `${eventId}_${currentUser.uid}`;
    const heartRef = doc(db, 'hearts', heartDocId);
    try {
      const existingHeart = await getDoc(heartRef);
      if (existingHeart.exists()) {
        await deleteDoc(heartRef);
        await updateUserData({ heartsCount: Math.max((userData?.heartsCount || 1) - 1, 0) });
        setHeartedEvents((prev) => prev.filter((id) => id !== eventId));
        setHeartCounts((prev) => ({
          ...prev,
          [eventId]: Math.max((prev[eventId] || 1) - 1, 0),
        }));
      }
    } catch {
      console.error('Error removing heart');
    }
  };

  const onHeartClick = async (e) => {
    stopPropagation(e);
    if (isHearted) {
      await removeHeart(id);
    } else {
      const success = await addHeart(id);
      if (success) {
        setAnimateHeart(true);
        setTimeout(() => setAnimateHeart(false), 300);
      } else {
        setFeedbackMessage('Like limit reached');
        setOpenSnackbar(true);
      }
    }
  };

  const addPin = async (eventId) => {
    const limit = getLimit('pins');
    const count = Number(localStorage.getItem('pinsCount') || 0);
    let lastReset = localStorage.getItem('pinsLastReset');
    let needReset = true;

    if (lastReset) {
      lastReset = new Date(lastReset);
      const now = new Date();
      const diff = now - lastReset;
      needReset = diff > 7 * 24 * 60 * 60 * 1000;
    }

    if (needReset) {
      localStorage.setItem('pinsCount', '0');
      localStorage.setItem('pinsLastReset', new Date().toISOString());
    }

    if (count >= limit && !needReset) {
      return false;
    }

    try {
      const userId = currentUser ? currentUser.uid : 'guest';
      const pinDocId = `${eventId}_${userId}`;
      const pinRef = doc(db, 'pins', pinDocId);

      const existingPin = await getDoc(pinRef);
      if (!existingPin.exists()) {
        await setDoc(pinRef, {
          eventId,
          userId,
          timestamp: Timestamp.now(),
        });
        if (currentUser) {
          await updateUserData({ pinsCount: (userData?.pinsCount || 0) + 1 });
        } else {
          localStorage.setItem('pinsCount', String(count + 1));
        }
        setPinnedEvents((prev) => [...prev, eventId]);
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  const removePin = async (eventId) => {
    try {
      const userId = currentUser ? currentUser.uid : 'guest';
      const pinDocId = `${eventId}_${userId}`;
      const pinRef = doc(db, 'pins', pinDocId);

      await deleteDoc(pinRef);
      if (currentUser) {
        await updateUserData({ pinsCount: Math.max((userData?.pinsCount || 1) - 1, 0) });
      } else {
        const count = Number(localStorage.getItem('pinsCount') || 1);
        localStorage.setItem('pinsCount', String(Math.max(count - 1, 0)));
      }
      setPinnedEvents((prev) => prev.filter((id) => id !== eventId));
    } catch {
      console.error('Error removing pin');
    }
  };

  const onPinClick = async (e) => {
    stopPropagation(e);
    if (isPinned) {
      await removePin(id);
      setFeedbackMessage('Unpinned');
    } else {
      const success = await addPin(id);
      if (success) {
        setFeedbackMessage('Pinned');
      } else {
        setFeedbackMessage('Pin limit reached');
      }
    }
    setOpenSnackbar(true);
  };

  const handleShareClick = async (e) => {
    stopPropagation(e);
    const link = `${window.location.origin}/events/${id}`;
    try {
      await navigator.clipboard.writeText(link);
      setFeedbackMessage('Link Copied');
    } catch {
      setFeedbackMessage('Failed to copy link');
    }
    setOpenSnackbar(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleCardClick();
    if (e.key === 'ArrowLeft' && hasMultipleImages && layout === 'grid') handlePrevImage(e);
    if (e.key === 'ArrowRight' && hasMultipleImages && layout === 'grid') handleNextImage(e);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => hasMultipleImages && layout === 'grid' && handleNextImage(),
    onSwipedRight: () => hasMultipleImages && layout === 'grid' && handlePrevImage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const isList = layout === 'list';

  return (
    <>
      <Card
        ref={cardRef}
        tabIndex={0}
        onClick={handleCardClick}
        onKeyDown={handleKeyDown}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          transition: 'transform 0.3s, box-shadow 0.3s',
          cursor: 'pointer',
          height: isList ? 'auto' : '320px',
          width: {
            xs: '100%',
            sm: isBookmarkPage ? '280px' : isList ? '100%' : '200px',
          },
          '&:hover': {
            transform: 'scale(1.03)',
            boxShadow: 6,
          },
          display: 'flex',
          flexDirection: isList ? 'row' : 'column',
          alignItems: isList ? 'center' : 'flex-start',
          outline: 'none',
          '&:focus': {
            boxShadow: `0 0 0 3px ${theme.palette.primary.main}`,
          },
          ...handlers,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
          setIsImageHovered(false);
        }}
      >
        {!isList && (
          <Box
            ref={imageRef}
            onMouseEnter={() => setIsImageHovered(true)}
            onMouseLeave={() => setIsImageHovered(false)}
            sx={{
              position: 'relative',
              flexGrow: 1,
              width: '100%',
              height: '160px',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          >
            <CardMedia
              component="img"
              height="160"
              image={images?.[imageIndex] || 'https://via.placeholder.com/200'}
              alt={title || 'Event Image'}
              loading="lazy"
              sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />

            {shouldShowLeftArrow && (
              <Tooltip title="Previous" arrow>
                <IconButton
                  onClick={handlePrevImage}
                  aria-label="previous image"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    color: theme.palette.text.primary,
                    '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
                    zIndex: 2,
                  }}
                  disableRipple
                >
                  <IoArrowBack size={20} />
                </IconButton>
              </Tooltip>
            )}

            {shouldShowRightArrow && (
              <Tooltip title="Next" arrow>
                <IconButton
                  onClick={handleNextImage}
                  aria-label="next image"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    color: theme.palette.text.primary,
                    '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
                    zIndex: 2,
                  }}
                  disableRipple
                >
                  <IoArrowForward size={20} />
                </IconButton>
              </Tooltip>
            )}

            {hasMultipleImages && (
              <DotsContainer>
                {images.map((_, idx) => (
                  <Dot
                    key={idx}
                    active={idx === imageIndex}
                    onClick={(e) => {
                      stopPropagation(e);
                      handleDotClick(idx);
                    }}
                  />
                ))}
              </DotsContainer>
            )}

            {isHovered && showIcons && (
              <Tooltip title="Share" arrow>
                <IconButton
                  onClick={handleShareClick}
                  aria-label="share"
                  sx={{
                    position: 'absolute',
                    top: '8px',
                    right: '10px',
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    color: theme.palette.text.primary,
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255,0.9)',
                    },
                    zIndex: 3,
                  }}
                  disableRipple
                >
                  <IoShareOutline size={20} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: isList ? 'center' : 'flex-start',
            alignItems: 'center',
            padding: '16px',
            width: isList ? 'calc(100% - 180px)' : '100%',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '180px',
              color: theme.palette.mode === 'dark' ? '#fff' : 'inherit',
            }}
            title={title || 'No Title Available'}
          >
            {title ? (title.length > 21 ? `${title.substring(0, 21)}...` : title) : 'No Title Available'}
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              marginBottom: '8px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '180px',
              color: theme.palette.mode === 'dark' ? '#fff' : 'inherit',
            }}
            title={locations?.join(', ') || 'Location Not Specified'}
          >
            {locations?.join(', ').length > 24
              ? `${locations.join(', ').substring(0, 24)}...`
              : locations?.join(', ') || 'Location Not Specified'}
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ marginBottom: '8px', maxWidth: '180px', color: theme.palette.mode === 'dark' ? '#fff' : 'inherit' }}
          >
            {dateTime?.toDate
              ? `${new Date(dateTime.toDate()).toLocaleDateString()} | ${new Date(dateTime.toDate()).toLocaleTimeString([], {
                  hour: 'numeric',
                  minute: '2-digit',
                })}`
              : 'Date/Time Not Available'}
          </Typography>

          {showIcons && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 'auto',
                padding: '8px 0',
                gap: '16px',
              }}
            >
              {currentUser ? (
                <Tooltip title="Comment" arrow>
                  <IconButton
                    onClick={(e) => {
                      stopPropagation(e);
                      setOpenCommentDialog(true);
                    }}
                    aria-label="comment"
                    sx={{
                      color: theme.palette.text.primary,
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                      transition: 'color 0.3s',
                    }}
                    disableRipple
                  >
                    <BsChat size={24} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Login to comment" arrow>
                  <IconButton
                    onClick={(e) => {
                      stopPropagation(e);
                      navigate('/login');
                    }}
                    aria-label="login to comment"
                    sx={{
                      color: 'gray',
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                      transition: 'color 0.3s',
                    }}
                    disableRipple
                  >
                    <BsChat size={24} />
                  </IconButton>
                </Tooltip>
              )}

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                {currentUser ? (
                  <Tooltip title="Like" arrow>
                    <AnimatedIconButton
                      onClick={onHeartClick}
                      aria-label="like"
                      animate={animateHeart}
                      sx={{
                        color: isHearted ? theme.palette.primary.main : 'inherit',
                        '&:hover': { color: theme.palette.primary.main },
                        transition: 'color 0.3s',
                      }}
                      disableRipple
                    >
                      {isHearted ? <BsFillHeartFill size={24} /> : <BsHeart size={24} />}
                    </AnimatedIconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Login to like" arrow>
                    <IconButton
                      onClick={(e) => {
                        stopPropagation(e);
                        navigate('/login');
                      }}
                      aria-label="login to like"
                      sx={{
                        color: 'gray',
                        '&:hover': { color: theme.palette.primary.main },
                        transition: 'color 0.3s',
                      }}
                      disableRipple
                    >
                      <BsHeart size={24} />
                    </IconButton>
                  </Tooltip>
                )}
                {heartCount > 0 && (
                  <Typography variant="body2" sx={{ minWidth: '20px', textAlign: 'left' }}>
                    {heartCount}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Tooltip title={isPinned ? 'Unpin' : 'Pin'} arrow>
                  <IconButton
                    onClick={onPinClick}
                    aria-label="pin"
                    sx={{
                      color: isPinned ? theme.palette.primary.main : 'inherit',
                      '&:hover': { color: theme.palette.primary.main },
                      transition: 'color 0.3s',
                    }}
                    disableRipple
                  >
                    {isPinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )}
        </Box>
      </Card>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={<Box sx={{ display: 'inline-block' }}>{feedbackMessage}</Box>}
        ContentProps={{
          sx: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            textAlign: 'center',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto',
            maxWidth: 'none',
            padding: '8px 16px',
            borderRadius: '4px',
          },
        }}
      />

      <Dialog
        open={openCommentDialog}
        onClose={() => setOpenCommentDialog(false)}
        aria-labelledby="comment-dialog-title"
      >
        <DialogTitle id="comment-dialog-title">
          Comment on "{title || 'this event'}"
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="Comment"
            type="text"
            fullWidth
            variant="standard"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCommentDialog(false)}>Cancel</Button>
          <Button onClick={() => { /* Handle comment submission */ }} disabled={commentText.trim() === ''}>
            Post
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardComponent;