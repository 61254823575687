// src/p/Create.js

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Container,
  Grid,
  Autocomplete,
  Box,
  Alert,
  IconButton,
  Chip,
  useMediaQuery,
  GlobalStyles,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { auth, db, storage } from '../firebase'; // Ensure correct Firebase configuration
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  Timestamp,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Create = () => {
  const [title, setTitle] = useState('');
  const [locations, setLocations] = useState([]);
  const [inputLocation, setInputLocation] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // All Locations Sorted Alphabetically
  const allLocations = [
    '1855 Place',
    '1407 S Harrison Rd',
    '1969 Campus Street',
    '1855 Place (Spartan Spirit Shop)',
    '1855 Place (Spartan Spirit Shop at 1855 Place)',
    'Akate Hall',
    'Akers Hall',
    'Alice B. Cowles House',
    'Alumni Memorial Chapel',
    'Anthony Hall',
    'Armstrong Hall',
    'Bailey Hall',
    'Baker Hall',
    'Baker Woodlot and Rajendra Neotropical Migrant Bird Sanctuary',
    'Beal Botanical Garden',
    'Beaumont Tower',
    'Bessey Hall',
    'Benefactors Plaza',
    'Berkey Hall',
    'BPS Gardens',
    'Bryan Hall',
    'Butterfield Hall',
    'Biosystems and Agricultural Engineering',
    'Bioengineering Facility',
    'Box Farm',
    'Box Woodland',
    'Box Woodland (duplicated if needed)',
    'Box Woodland (possible duplicate)',
    'Plant Pathology Research',
    'Parking Area 61',
    'MSU FPIC',
    'Demmer Shooting Sports and Education',
    'MSU Mink Building Poultry Teaching and Research Center',
    'University Farms Service Center',
    'Swine Teaching and Research',
    'Parking Lot 79',
    'School of Human Resources and Labor Relations',
    'Parking Lot 10',
    'James Madison College',
    'Football Practice Field',
    'MSU Bikes Service Center',
    'Outdoor Tennis Courts (South Campus)',
    'Erickson Hall',
    'Parking Lot 72',
    'Panda Express',
    'Parking Lot 78',
    'Office for Education Abroad',
    'Skandalaris Football Center',
    'Spartan Bookstore',
    'Smith Student-Athlete Academic Center',
    'Parking Lot 50',
    'Clara Bell Smith Center',
    'Parking Lot 39',
    'Daugherty Football Building',
    'Parking Lot 40',
    'Infrastructure Planning and Facilities Building',
    'Parking Lot 60',
    'Parking Lot 38',
    'Parking Lot 59',
    'Engineering Building',
    'Landscape Services',
    'CMSE Department',
    'Parking Lot 77',
    'MSU Police/Public Safety Building',
    'Department of Animal Science',
    'Parking Lot 76',
    'MSU Dairy Store',
    'Parking Lot 58',
    'Parking Lot 43',
    'Oyer Speech and Hearing',
    'GM Trout FSHN Building',
    'Urban Planning and Landscape Architecture',
    'Meat Lab',
    'Instructional Media Center',
    'Parking Lot 42',
    'Parking Lot 57',
    'Multicultural Center',
    'Reservoir',
    'Packaging Building',
    'Parking Lot 56',
    'Communication Arts and Sciences Building',
    'STEM Teaching and Learning Facility',
    'College of Communication Arts',
    'Horse Teaching and Research',
    'Stallion Barn',
    'Teaching and Research Barn',
    'Garage and Implement Shed',
    'Storage/Exercise Building',
    'Endocrine Research',
    'Dairy Cattle Teaching and Research',
    'South Campus Composting Facility',
    'Pollinator Performance Center',
    'Veterinary Diagnostic Laboratory',
    'Parking Area 55',
    'Parking Lot 52',
    'Parking Lot 202',
    'English Language Center',
    'School of Packaging',
    'Wells Hall',
    'Natural Resources Building',
    'Career Services Employer Engagement Center',
    'Parking Lot 48',
    'Office of Admissions/Hannah Administration Building',
    'Parking Lot 11',
    'Parking Ramp #5',
    'MSU Computer Center',
    'MSU Fisheries and Wildlife Club Restoration Area',
    'MSU Tech Store',
    'Parking Lot 47',
    'Parking Ramp #2',
    'Plant Science Greenhouses',
    'Parking Lot 6',
    'Parking Lot 53',
    'College of Arts and Letters',
    'Food Safety and Toxicology',
    'University Conference Services',
    'Plant Biology Teaching Conservatory',
    'MSU Union',
    'Center for Integrated Plant Systems',
    'Human Ecology Building',
    'MSU Horticulture Gardens',
    'Parking Lot 4',
    'Support Structure',
    'Parking Ramp #6',
    'Michigan 4-H Children’s Garden',
    'MSU Student Food Bank',
    'Parking Lot 54',
    'Plant and Soil Science Building',
    'Honors College/Eustace-Cole Building',
    'Plant Biology Laboratories',
    'Marshall-Adams',
    'MSU-DOE Plant Research Laboratory',
    'MSU Old Botany Building',
    'Molecular Plant Sciences Building',
    'Chittenden Hall',
    'Cyclotron (FRIB)',
    'Physics and Astronomy Department',
    'Cook Hall',
    'Chemistry Building',
    'Department of Biochemistry and Molecular Biology',
    'Agriculture Hall',
    'MSU Biochemistry Building',
    'College of Agriculture and Natural Resources',
    'Kedzie Hall',
    'Biomedical and Physical Sciences Building',
    'Department of Political Science',
    'McLaren Greater Lansing Health and Wellness Pavilion',
    'MSU MBI',
    'McLaren Greater Lansing Wound and Hyperbaric Center',
    'Izzo Family Medical Center',
    'Corewell Health Helen DeVos Children’s Pediatric',
    'Entomology Field Research',
    'MSU Environmental Health and Safety',
    'MSU Office of Research Regulatory',
    'Subway',
    'The Roost',
    'Panda Express',
    'Parking Lot 41',
    'Abrams Planetarium',
    'WDBM-FM Radio',
    'Parking Lot 36',
    'Shaw Hall',
    'Parking Ramp #1',
    'Parking Lot 35',
    'The Rock',
    'MSU Auditorium',
    'Fairchild Theatre',
    'Department of Theatre',
    'Giltner Hall',
    'College of Natural Science',
    'Natural Science Building',
    'Student Affairs and Services',
    'Old Horticulture College',
    'Parking Lot 8',
    'Parking Lot 9',
    'Eli and Edythe Broad Art Museum',
    'Mason Hall',
    'Department of Psychology',
    'Psychology Building',
    'Parking Lot 2',
    'Phillips Hall',
    'Snyder Hall',
    'Panera Bread',
    'Parking Lot 1',
    'Baker Hall',
    'Geography Building',
    'Alumni Memorial Chapel',
    'Kresge Art Center',
    'Eli Broad College of Business',
    'MSU Broad Store',
    'CATA Bus Station',
    'Law College',
    'MSU College of Law',
    'MSU Department of Management',
    'Parking Lot 34',
    'Parking Ramp #3',
    'Parking Lot 26',
    'MSU Veterinary Medical Center',
    'Comparative Oncology',
    'Small Animal Clinic',
    'Large Animal Clinic',
    'Parking Lot 27',
    'College of Veterinary Medicine',
    'Veterinary Medicine Fields',
    'Pegasus Critical Care',
    'Neville’s Field - MSU Rugby Pitch',
    'McPhail Equine Performance',
    'MSU Tennis Courts (East Campus)',
    'Fee Hall',
    'MSU Health Care',
    'MSU Department of PA Medicine',
    'College of Osteopathic Medicine',
    'Parking Lot 32',
    'Parking Lot 30',
    'Hubbard Hall',
    'MSU Archives',
    'Conrad Hall',
    'Parking Lot 29',
    'Akers Hall',
    'Parking Lot 28',
    'MSU Human Medicine Office',
    'MSU Gen',
    'Energy and Automotive Research',
    'Veterinary Medicine Bennett Farm',
    'Sheep Teaching and Research Center',
    'Tree Research Center',
    'University Club of MSU',
    'Henry Center for Executive Development',
    'Candlewood Suites East Lansing',
    'McLaren Greater Lansing',
    'McLaren Laboratory',
    'IM East Fitness Center',
    'IM East Field',
    'Holmes Hall',
    'Lyman Briggs College',
    'Parking Lot 20',
    'McDonel Hall',
    'Parking Lot 21',
    'Parking Lot 22',
    'Sanford Natural Area',
    'Sanford Woodlot Trail',
    'Red Cedar River',
    'Parking Lot 91',
    'Spartan Greens Turf Complex',
    'Parking Lot 92',
    'Parking Lot 100',
    'Crop and Soil Teaching and Research',
    'Parking Lot 96',
    'Department of Radiology',
    'MSU Health Care Neurology/Ophthalmology',
    'Parking Lot 90',
    'Bioengineering Facility',
    'College of Nursing',
    'Interdisciplinary Science Technology Building',
    'Life Science',
    'Engineering Research Complex',
    'Chilled Water Plant',
    'Lewis Landscape Arboretum',
    'Parking Lot 101',
    'Parking Lot 68',
    'Spartan Linen Services',
    'Cooling Tower',
    'T.B. Simon Power Plant',
    'Parking Lot 83',
    'Parking Lot 82',
    'Parking Lot 81',
    'University Services',
    'Parking Lot 99',
    'University Stores',
    'Angell Hall',
    'Minskoff Pavilion',
    'MSU Bakers',
    'Parking Lot 84',
    'MSU Food Stores',
    'Parking Lot 89',
    'Parking Lot 87',
    'Manly Miles Building',
    'Parking Lot 85',
    'Parking Lot 86',
    'Nisbet Hall',
    'Parking Lot 93',
    'Minnis Woodland',
    'Michigan Department of Agriculture and Rural Development',
    'USDA Agricultural Research Services',
    'Agricultural Exposition Site',
    'MSU IT Services Infrastructure',
    'MSU Surplus Store',
    'MSU Recycling Center/Public Drop-Off',
    'MSU Federal Credit Union',
    'MSU Infrastructure Planning and Facilities Storage Building #2',
    'MSU Scene Shop',
    'William A. Demmer Engineering Center',
    'Forest Akers East Golf Course',
    'Parking Lot 200',
    'MSU Observatory',
    'Hancock Turfgrass Research Center',
    'MSU Agronomy Farm',
    'University Research Containment Facility',
    'Parking Lot 201',
    'MSU Pavilion for Agriculture and Livestock Education',
    'Rather Hall',
    'Butterfield Hall',
    'Bryan Hall',
    'Brody Hall',
    'Brody Square',
    'Parking Lot 65',
    'Armstrong Hall',
    'Bailey Hall',
    'Emmons Hall',
    'Kellogg Hotel and Conference Center',
    'Parking Ramp No. 4',
    'Parking Lot 66',
    'Parking Lot 66A',
    'Fisheries Research and Aquaculture Center',
    'Starbucks',
    'MSU RHS Information Services',
    'Spartan Spirit Shop at 1855 Place',
    'Parking Ramp 7/Parking Lot 14',
    'Cherry Lane Park',
    'MSU Visitor Welcome Center',
    'Holden Hall',
    'Wilson Hall',
    'Case Hall',
    'Wonders Hall',
    'Parking Lot 71',
    'Student Recreation and Wellness Center',
    'East Lansing Fire Department',
    'Parking Lot 63',
    'Forest Akers Trust Practice Complex',
    'Munn Intramural Field',
    'Munn Ice Arena',
    'Ralph Young Field',
    'IM West Fitness Center',
    'Breslin Center',
    'Demonstration Hall',
    'Parking Lot 67',
    'Jenison Field House',
    'Parking Lot 62',
    'Spartan Statue',
    'DeMartin Soccer Complex',
    'Kobs Field',
    'Secchia Stadium',
    'Williams Hall',
    'Gilchrist Hall',
    'Wills House',
    'Landon Hall',
    'Yakeley Hall',
    'Mary Mayo Hall',
    'Campbell Hall',
    'Adams Field',
    'Michigan State University College of Music',
    'Music Building',
    'Main Library',
    'MSU Museum',
    'Olds Hall',
    'Spartan Stadium',
    'Student Organic Farm',
  ].sort((a, b) => a.localeCompare(b));

  const isFormComplete =
    title.trim() &&
    locations.length > 0 &&
    date.trim() &&
    time.trim() &&
    images.length > 0;

  // Initialize Sensors for @dnd-kit
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Handle Image Selection
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (images.length + files.length > 3) {
      setError('Upload up to 3 images!');
      return;
    }
    const newImages = files.map((file, index) => ({
      file,
      preview: URL.createObjectURL(file),
      id: `image-${Date.now()}-${index}`, // Ensure unique ID
    }));
    setImages((prev) => [...prev, ...newImages]);
    setError(null);
    console.log('Images after addition:', [...images, ...newImages]); // Debugging log
  };

  // Handle Image Removal
  const handleImageRemove = (id) => {
    setImages((prev) => {
      const updated = prev.filter((img) => img.id !== id);
      const removed = prev.find((img) => img.id === id);
      if (removed) URL.revokeObjectURL(removed.preview);
      console.log('Images after removal:', updated); // Debugging log
      return updated;
    });
  };

  // Handle Drag End
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setImages((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const reordered = arrayMove(items, oldIndex, newIndex);
        console.log('Images after drag:', reordered); // Debugging log
        return reordered;
      });
    }
  };

  // Check Event Limit per User per Month
  const checkEventLimit = async () => {
    const user = auth.currentUser;
    if (!user) return 0;

    const startOfMonth = new Date();
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);
    const endOfMonth = new Date(startOfMonth);
    endOfMonth.setMonth(endOfMonth.getMonth() + 1);

    const q = query(
      collection(db, 'events'),
      where('creatorId', '==', user.uid),
      where('createdAt', '>=', Timestamp.fromDate(startOfMonth)),
      where('createdAt', '<', Timestamp.fromDate(endOfMonth))
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormComplete) {
      setError(
        'Please fill in all fields, select at least one location, and upload at least one image.'
      );
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const user = auth.currentUser;
      if (!user) {
        setError('Log in to create!');
        setLoading(false);
        return;
      }

      const eventCount = await checkEventLimit();
      if (eventCount >= 3) {
        setError('You have reached the limit of 3 events for this month.');
        setLoading(false);
        return;
      }

      const imageUrls = [];
      for (const img of images) {
        try {
          const storageRefPath = `eventImages/${user.uid}/${Date.now()}_${img.file.name}`;
          const storageRef = ref(storage, storageRefPath);
          const snapshot = await uploadBytes(storageRef, img.file);
          const url = await getDownloadURL(snapshot.ref);
          imageUrls.push(url);
          console.log(`Uploaded ${img.file.name} to ${url}`); // Debugging log
        } catch (uploadError) {
          console.error(`Error uploading image ${img.file.name}:`, uploadError);
          throw uploadError;
        }
      }

      const dateTimeString = `${date}T${time}:00`;
      const eventDateTime = new Date(dateTimeString);
      const timestamp = Timestamp.fromDate(eventDateTime);
      const eventData = {
        title,
        locations,
        dateTime: timestamp,
        images: imageUrls,
        creatorId: user.uid,
        creatorEmail: user.email,
        createdAt: Timestamp.now(),
      };

      await addDoc(collection(db, 'events'), eventData);

      setSuccess('Event created successfully!');
      setTitle('');
      setLocations([]);
      setDate('');
      setTime('');
      setImages([]);
      navigate('/explore');
      console.log('Event created successfully'); // Debugging log
    } catch (err) {
      console.error('Error creating event:', err);
      setError(`An error occurred while creating the event: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Auto-Clear Error Messages after 5 Seconds
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  // Auto-Clear Success Messages after 5 Seconds
  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => setSuccess(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  // Cleanup Object URLs on Unmount
  useEffect(() => {
    return () => {
      images.forEach((img) => URL.revokeObjectURL(img.preview));
    };
  }, [images]);

  // Custom Filter for Autocomplete
  const customFilterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option,
  });

  // Filtered Locations Based on Input
  const filteredOptions =
    inputLocation.length > 0
      ? allLocations.filter((loc) =>
          loc.toLowerCase().startsWith(inputLocation.toLowerCase())
        )
      : [];

  // Control Autocomplete Dropdown Visibility
  useEffect(() => {
    if (inputLocation.length > 0 && filteredOptions.length > 0) {
      setOpenAutocomplete(true);
    } else {
      setOpenAutocomplete(false);
    }
  }, [inputLocation, filteredOptions]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        py: isMobile ? 2 : 3,
        px: isMobile ? 1 : 3,
        position: 'relative',
        overflow: 'visible',
        mt: isMobile ? 2 : 4,
      }}
    >
      <GlobalStyles
        styles={{
          '*::-webkit-scrollbar': {
            width: '12px',
          },
          '*::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '6px',
          },
          '*::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
            borderRadius: '6px',
          },
          '*::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.primary.dark,
          },
        }}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
        id="create-form"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 0,
        }}
        noValidate
      >
        <Grid container spacing={isMobile ? 1 : 2} justifyContent="center">
          {!isMobile && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  gap: 2,
                  width: '90%',
                  borderRadius: '60px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  padding: 3,
                  mt: -4,
                  mx: 'auto',
                  overflow: 'visible',
                }}
              >
                {/* Title Field */}
                <TextField
                  label="Title"
                  value={title}
                  onChange={(e) => {
                    if (e.target.value.length <= 100) setTitle(e.target.value);
                  }}
                  fullWidth
                  inputProps={{
                    maxLength: 100,
                    autoComplete: 'off',
                    style: { textAlign: 'center' },
                  }}
                  variant="outlined"
                  sx={{
                    width: '25%',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '60px',
                    },
                  }}
                  InputLabelProps={{ required: false }}
                />

                {/* Location Autocomplete */}
                <Autocomplete
                  multiple
                  options={filteredOptions}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    if (newValue.length <= 10) {
                      setLocations(newValue);
                      setError(null);
                    } else {
                      setError('You can select up to 10 locations.');
                    }
                  }}
                  inputValue={inputLocation}
                  onInputChange={(event, newInputValue) =>
                    setInputLocation(newInputValue)
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                        key={`${option}-${index}`} // Ensuring unique key
                        sx={{
                          margin: '6px',
                        }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                        style: { textAlign: 'center' },
                      }}
                      InputLabelProps={{ required: false }}
                    />
                  )}
                  filterOptions={(options, state) => {
                    const filtered = customFilterOptions(options, state);
                    return isMobile ? filtered.slice(0, 1) : filtered.slice(0, 2);
                  }}
                  sx={{
                    width: '45%',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '60px',
                    },
                  }}
                  disableClearable
                  noOptionsText=""
                  open={openAutocomplete}
                />

                {/* Date Field */}
                <TextField
                  label="Date"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true, required: false }}
                  inputProps={{
                    min: new Date().toISOString().split('T')[0],
                    max: '2024-12-31',
                    autoComplete: 'off',
                    style: { textAlign: 'center' },
                  }}
                  variant="outlined"
                  sx={{
                    width: '23%',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      '& fieldset': {
                        borderColor: theme.palette.divider,
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  }}
                />

                {/* Time Field */}
                <TextField
                  label="Time"
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true, required: false }}
                  inputProps={{
                    step: 300,
                    autoComplete: 'off',
                    style: { textAlign: 'center' },
                  }}
                  variant="outlined"
                  sx={{
                    width: '19.75%',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      '& fieldset': {
                        borderColor: theme.palette.divider,
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  }}
                />

                {/* Image Upload Button */}
                <IconButton
                  component="label"
                  aria-label="upload images"
                  sx={{
                    color: theme.palette.primary.main,
                    width: '5%',
                    padding: '6px',
                    borderRadius: '50%',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <AttachFileIcon fontSize="large" />
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    multiple
                    onChange={handleImageChange}
                  />
                </IconButton>
              </Box>
            </Grid>
          )}

          {/* Mobile View */}
          {isMobile && (
            <>
              <Grid item xs={12}>
                {/* Title Field */}
                <TextField
                  label="Title"
                  value={title}
                  onChange={(e) => {
                    if (e.target.value.length <= 100) setTitle(e.target.value);
                  }}
                  fullWidth
                  inputProps={{
                    maxLength: 100,
                    autoComplete: 'off',
                    style: { textAlign: 'center' },
                  }}
                  variant="outlined"
                  sx={{
                    mb: 2,
                  }}
                  InputLabelProps={{ required: false }}
                />
              </Grid>

              <Grid item xs={12}>
                {/* Location Autocomplete */}
                <Autocomplete
                  multiple
                  options={filteredOptions}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    if (newValue.length <= 10) {
                      setLocations(newValue);
                      setError(null);
                    } else {
                      setError('You can select up to 10 locations.');
                    }
                  }}
                  inputValue={inputLocation}
                  onInputChange={(event, newInputValue) =>
                    setInputLocation(newInputValue)
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                        key={`${option}-${index}`} // Ensuring unique key
                        sx={{
                          margin: '4px',
                        }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                        style: { textAlign: 'center' },
                      }}
                      InputLabelProps={{ required: false }}
                    />
                  )}
                  filterOptions={(options, state) => {
                    const filtered = customFilterOptions(options, state);
                    return filtered.slice(0, 1);
                  }}
                  sx={{
                    width: '100%',
                    mb: 2,
                  }}
                  disableClearable
                  noOptionsText=""
                  open={openAutocomplete}
                />
              </Grid>

              <Grid item xs={12}>
                {/* Date Field */}
                <TextField
                  label="Date"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true, required: false }}
                  inputProps={{
                    min: new Date().toISOString().split('T')[0],
                    max: '2024-12-31',
                    autoComplete: 'off',
                    style: { textAlign: 'center' },
                  }}
                  variant="outlined"
                  sx={{
                    mb: 2,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {/* Time Field */}
                <TextField
                  label="Time"
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true, required: false }}
                  inputProps={{
                    step: 300,
                    autoComplete: 'off',
                    style: { textAlign: 'center' },
                  }}
                  variant="outlined"
                  sx={{
                    mb: 2,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {/* Image Upload Button */}
                <IconButton
                  component="label"
                  aria-label="upload images"
                  sx={{
                    color: theme.palette.primary.main,
                    width: '100%',
                    padding: '6px',
                    borderRadius: '8px',
                    backgroundColor: 'transparent',
                    border: `1px dashed ${theme.palette.primary.main}`,
                  }}
                >
                  <AttachFileIcon fontSize="large" />
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    multiple
                    onChange={handleImageChange}
                  />
                </IconButton>
              </Grid>
            </>
          )}

          {/* Image Drag-and-Drop Area */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                gap: isMobile ? 1 : 2,
                mt: isMobile ? 2 : 7,
              }}
            >
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={images.map((image) => image.id)}
                  strategy={horizontalListSortingStrategy}
                >
                  <Box
                    sx={{
                      display: images.length > 0 ? 'flex' : 'none',
                      gap: 2,
                      overflowX: 'auto',
                      width: '100%',
                      maxWidth: isMobile ? 360 : 500,
                      padding: 1,
                      border: `2px dashed ${theme.palette.primary.main}`,
                      borderRadius: 2,
                      justifyContent: 'center',
                    }}
                  >
                    {images.map((image) => (
                      <SortableImage
                        key={image.id}
                        id={image.id}
                        src={image.preview}
                        onRemove={() => handleImageRemove(image.id)}
                        isMobile={isMobile}
                      />
                    ))}
                  </Box>
                </SortableContext>
              </DndContext>

              {/* Error Alert */}
              {error && (
                <Alert
                  severity="error"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '50px',
                    padding: '10px 20px',
                    maxWidth: 'fit-content',
                    mt: 2,
                  }}
                >
                  {error}
                </Alert>
              )}

              {/* Success Alert */}
              {success && (
                <Alert
                  severity="success"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '50px',
                    padding: '10px 20px',
                    maxWidth: 'fit-content',
                    mt: 2,
                  }}
                >
                  {success}
                </Alert>
              )}
            </Box>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                mt: isMobile ? 2 : 2,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                aria-label="create event"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: '#fff',
                  borderRadius: '20px',
                  fontSize: '1.5rem',
                  textTransform: 'none',
                  width: '180px',
                  height: 50,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
                disabled={!isFormComplete || loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Create'
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

// SortableImage Component Defined Within Create.js
const SortableImage = ({ id, src, onRemove, isMobile }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: 'grab',
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      sx={{
        position: 'relative',
        width: isMobile ? 140 : 160,
        height: isMobile ? 140 : 160,
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: isDragging ? 3 : 1,
        backgroundColor: '#f0f0f0',
        border: `1px solid`,
        borderColor: 'divider',
      }}
    >
      <Box
        component="img"
        src={src}
        alt="Uploaded Preview"
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      <IconButton
        size="small"
        onClick={onRemove}
        sx={{
          position: 'absolute',
          top: 4,
          right: 4,
          backgroundColor: 'rgba(50, 50, 50, 0.6)',
          color: '#fff',
          '&:hover': {
            backgroundColor: 'rgba(50, 50, 50, 0.8)',
          },
        }}
      >
        <ClearIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default Create;