// Signup.js
import React, { useState, useContext } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../App';
import { auth, initializeUserDocument } from '../firebase';
import {
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTheme } from '@mui/material/styles';

const Signup = () => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const navigate = useNavigate();
  const { themeColor } = useContext(ThemeContext);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!email.trim() || !password.trim()) {
      setSnackbar({
        open: true,
        message: 'Please fill in all fields.',
        severity: 'warning',
      });
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await updateProfile(user, { displayName: 'New User' });
      await initializeUserDocument(user.uid, 'New User');
      setSnackbar({
        open: true,
        message: 'Account created successfully!',
        severity: 'success',
      });
      navigate('/profile');
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Email already in use',
        severity: 'error',
      });
    }
  };

  const isSignupDisabled = !email.trim() || !password.trim();

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 4,
        mb: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        color: theme.palette.text.primary,
      }}
    >
      <Typography variant="h4" gutterBottom textAlign="center">
        Create Account
      </Typography>
      <Box
        component="form"
        onSubmit={handleSignup}
        sx={{
          mt: 2,
          width: '100%',
          maxWidth: '400px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          aria-label="Email"
          InputProps={{
            style: { color: theme.palette.text.primary },
          }}
        />
        <TextField
          label="Password"
          type={isPasswordVisible ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          aria-label="Password"
          InputProps={{
            style: { color: theme.palette.text.primary },
            endAdornment:
              password.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      isPasswordVisible ? 'Hide password' : 'Show password'
                    }
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !isSignupDisabled) {
              handleSignup(e);
            }
          }}
        />
        <Button
          variant="contained"
          type="submit"
          fullWidth
          disabled={isSignupDisabled}
          sx={{
            backgroundColor: themeColor,
            color: '#fff',
            textTransform: 'none',
            fontSize: '1.1rem',
            '&:hover': { backgroundColor: themeColor },
          }}
          aria-label="Continue"
        >
          Continue
        </Button>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Signup;