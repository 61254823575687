// src/App.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import {
  Routes,
  Route as ReactRoute,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  Box,
  CssBaseline,
  useMediaQuery,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  GlobalStyles,
  Avatar,
} from '@mui/material';
import { GoHomeFill, GoHome } from 'react-icons/go';
import Home from './p/Home';
import Explore from './p/Explore';
import Upgrade from './p/Upgrade';
import Create from './p/Create';
import ProfilePage from './p/Profile';
import Passback from './p/Passback';
import Signup from './p/Signup';
import Login from './p/Login';
import Guide from './p/Guide';
import Event from './p/Event';
import PrivacyTermsPage from './p/Privacy';
import Sparxium from './p/Sparxium';
import Edu from './p/Edu';
import Notifications from './p/Notifications';
import ProtectedRoute from './c/Route';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { auth } from './firebase';

import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import MdOutlineExplore from '@mui/icons-material/ExploreOutlined';
import MdExplore from '@mui/icons-material/Explore';

export const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
  const [themeColorName, setThemeColorName] = useState(
    () => localStorage.getItem('themeColorName') || 'blue'
  );
  const [fontSize, setFontSize] = useState(
    () => parseInt(localStorage.getItem('fontSize'), 10) || 14
  );
  const [backgroundMode, setBackgroundMode] = useState(
    () => localStorage.getItem('backgroundMode') || 'Light'
  );

  useEffect(() => {
    localStorage.setItem('themeColorName', themeColorName);
  }, [themeColorName]);

  useEffect(() => {
    localStorage.setItem('fontSize', fontSize);
  }, [fontSize]);

  useEffect(() => {
    localStorage.setItem('backgroundMode', backgroundMode);
  }, [backgroundMode]);

  const colorMap = {
    red: '#f44336',
    orange: '#ff9800',
    yellow: '#ffeb3b',
    green: '#4caf50',
    blue: '#2196f3',
    purple: '#9c27b0',
    grey: '#9e9e9e',
  };

  const themeColor = colorMap[themeColorName] || colorMap['blue'];

  const theme = createTheme({
    typography: {
      fontSize: fontSize,
    },
    palette: {
      mode: backgroundMode === 'Dark' ? 'dark' : 'light',
      primary: { main: themeColor },
      secondary: { main: themeColor },
      background: {
        default:
          backgroundMode === 'Light'
            ? '#ffffff'
            : backgroundMode === 'Dim'
            ? '#696969'
            : '#000000',
        paper:
          backgroundMode === 'Light'
            ? '#ffffff'
            : backgroundMode === 'Dim'
            ? '#696969'
            : '#121212',
      },
      text: {
        primary: backgroundMode === 'Dark' ? '#ffffff' : '#000000',
      },
    },
  });

  return (
    <ThemeContext.Provider
      value={{
        themeColorName,
        setThemeColorName,
        themeColor,
        fontSize,
        setFontSize,
        backgroundMode,
        setBackgroundMode,
        colorMap,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

const App = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { themeColor, backgroundMode, fontSize } = useContext(ThemeContext);

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  const avatarUrl = currentUser?.photoURL || '';

  const desktopNavItems = [
    {
      label: 'Home',
      path: '/home',
      icons: [
        <GoHome size={fontSize * 2.5} key="home" />,
        <GoHomeFill size={fontSize * 2.5} key="homeFill" />,
      ],
    },
    {
      label: 'Edu',
      path: '/edu',
      icons: [
        <SchoolOutlinedIcon sx={{ fontSize: fontSize * 2.5 }} key="edu" />,
        <SchoolSharpIcon
          sx={{ fontSize: fontSize * 2.5, color: themeColor }}
          key="eduSelected"
        />,
      ],
    },
    {
      label: 'Explore',
      path: '/explore',
      icons: [
        <MdOutlineExplore sx={{ fontSize: fontSize * 2.5 }} key="explore" />,
        <MdExplore
          sx={{ fontSize: fontSize * 2.5, color: themeColor }}
          key="exploreSelected"
        />,
      ],
    },
    {
      label: 'Sparxium',
      path: '/sparxium',
      icons: [
        <ChatBubbleOutlineIcon
          sx={{ fontSize: fontSize * 2.5 }}
          key="sparxium"
        />,
        <ChatBubbleIcon
          sx={{ fontSize: fontSize * 2.5, color: themeColor }}
          key="sparxiumSelected"
        />,
      ],
    },
    {
      label: 'Profile',
      path: '/profile',
      icon: (
        <Avatar
          src={avatarUrl}
          sx={{ width: fontSize * 2.5, height: fontSize * 2.5 }}
          key="avatar"
        />
      ),
    },
  ];

  const mobileNavItems = desktopNavItems;

  const isSelected = (path) =>
    location.pathname === path || (path === '/home' && location.pathname === '/');

  return (
    <Box sx={{ display: 'flex' }}>
      {!isMobile && (
        <List
          sx={{
            width: '60px',
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'transparent',
            overflow: 'hidden',
            position: 'fixed',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {desktopNavItems.map(({ label, path, icons, icon }) => (
            <Tooltip key={label} title={label} placement="right" arrow>
              <ListItem
                button
                selected={isSelected(path)}
                onClick={() => navigate(path)}
                sx={{
                  justifyContent: 'center',
                  marginY: 1,
                  height: 60,
                  '&.Mui-selected': {
                    color: themeColor,
                    backgroundColor: 'transparent',
                  },
                  '&:hover': {
                    color: themeColor,
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    color: isSelected(path) ? themeColor : 'inherit',
                  }}
                >
                  {icon ? icon : isSelected(path) ? icons[1] : icons[0]}
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: isMobile ? '60px' : '20px',
          paddingRight: '20px',
          minHeight: '100vh',
          backgroundColor: 'background.default',
          color: 'text.primary',
          overflowX: 'hidden',
          overflowY: 'auto',
          ml: !isMobile ? '60px' : 0,
        }}
      >
        <Routes>
          <ReactRoute path="/" element={<Navigate to="/home" replace />} />
          <ReactRoute path="/home" element={<Home />} />
          <ReactRoute path="/explore/*" element={<Explore />} />
          <ReactRoute path="/events/:id" element={<Event />} />
          <ReactRoute path="/upgrade" element={<Upgrade />} />
          <ReactRoute path="/create" element={<Create />} />
          <ReactRoute path="/login" element={<Login />} />
          <ReactRoute path="/signup" element={<Signup />} />
          <ReactRoute path="/passback" element={<Passback />} />
          <ReactRoute path="/privacy" element={<PrivacyTermsPage />} />
          <ReactRoute path="/guide" element={<Guide />} />
          <ReactRoute path="/sparxium" element={<Sparxium />} />
          <ReactRoute path="/edu/*" element={<Edu />} />
          <ReactRoute path="/notifications" element={<Notifications />} />
          <ReactRoute
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <ReactRoute path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Box>
      {isMobile && (
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor:
              backgroundMode === 'Dark'
                ? theme.palette.background.default
                : '#fff',
          }}
          elevation={3}
        >
          <BottomNavigation
            value={location.pathname}
            onChange={(event, newValue) => {
              navigate(newValue);
            }}
            showLabels={false}
            sx={{
              backgroundColor: 'transparent',
            }}
          >
            {mobileNavItems.map(({ label, path, icons, icon }) => {
              const selected = isSelected(path);
              return (
                <Tooltip key={path} title={label} placement="top" arrow>
                  <BottomNavigationAction
                    value={path}
                    icon={icon ? icon : selected ? icons[1] : icons[0]}
                    sx={{
                      color: backgroundMode === 'Dark' ? '#fff' : 'black',
                      '&.Mui-selected': {
                        color: themeColor,
                      },
                      '&:hover': {
                        color: themeColor,
                      },
                    }}
                    disableRipple
                    onClick={() => navigate(path)}
                  />
                </Tooltip>
              );
            })}
          </BottomNavigation>
        </Paper>
      )}
      <GlobalStyles
        styles={{
          '*::-webkit-scrollbar': {
            width: '12px',
          },
          '*::-webkit-scrollbar-track': {
            background: theme.palette.background.default,
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '20px',
            border: '3px solid transparent',
            backgroundClip: 'content-box',
          },
          body: {
            margin: 0,
            padding: 0,
            overflow: 'hidden',
          },
        }}
      />
    </Box>
  );
};

export default App;